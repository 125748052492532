/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useState } from "react";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

import {
    InterfaceButton,
    InterfaceThirdButton,
} from "./MechCategoryStyles";
import { drawZipImages } from "./../../ExportImages";

import { UserAuthContext } from "./../../../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "./../../../../Analytics/AnalyticsContextWrapper";
import {
    BrandedMechPrintAnalytics,
    NPCMechPrintAnalytics,
    CustomMechPrintAnalytics,
    CustomPlusMechPrintAnalytics,
    safeName,
    nameRegex,
 } from "./../../../Print/PrintAnalytics";
 import ExportWrapper from "../../components/ExportWrapper";

import {
    brandList as mechNPCBrandList,
    npcOptionList,
    npcLayerList,
    npcLayerOrder,
} from "../../../LayerData/MechNPCLayerOrder";
import {
    brandList as mechBrandedBrandList,
    brandedOptionList,
    brandedLayerList,
    brandedLayerOrder,
} from "../../../LayerData/MechBrandedLayerOrder";

import Mechs from "./../../../data/Mechs-GMS";
import MechsCustomPlus from "./../../../data/Mechs-622";

import { premiumTiersList } from "../../PremiumPopup";

import { createExportLayerOptionsForAllParts } from "../../../LayerData/CreateLayerFunctions";

const brandList = Object.assign({},
    mechNPCBrandList,
    mechBrandedBrandList,
);

const canvasRef = createRef();

function ExportAllMechPartsButton({ displayTheme, unit, name, mechType, setShowPremiumPopupFunc, disabled = false, isButtonThird = false }) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);

    const isLegendaryUser = user && user.isLegendaryUser;

    const [isLoading, setIsLoading] = useState(false);

    let imageSize = 250;

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isLarge) {
        imageSize = 200;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeus) {
        imageSize = 300;
    }

    if (
        mechType === "CUSTOM-PLUS"
        || (mechType !== "CUSTOM" && brandList[unit.brandValue][unit.frameValue].isMegadeusPlus)
    ) {
        imageSize = 350;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus) {
        imageSize = 400;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusOmega) {
        imageSize = 450;
    }

    async function printCustomMech(unit) {
        let imageLayersArray = [];

        function addAccessories(accessoryList, tintValue, tintIndex) {
            accessoryList.forEach((accessory) => {
                if (accessory.backSrc) {
                    imageLayersArray.push({
                        src: accessory.backSrc,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-back.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (accessory.src) {
                    imageLayersArray.push({
                        src: accessory.src,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-mid.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (accessory.frontSrc) {
                    imageLayersArray.push({
                        src: accessory.frontSrc,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-front.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (accessory.foremostSrc) {
                    imageLayersArray.push({
                        src: accessory.foremostSrc,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-foremost.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (Mechs.accessory.length) {
            addAccessories(Mechs.accessory, unit.accessory1TintValue, 1);

            if (unit.accessory2TintValue !== "none") {
                addAccessories(Mechs.accessory, unit.accessory2TintValue, 2);
            }

            if (unit.accessory3TintValue !== "none") {
                addAccessories(Mechs.accessory, unit.accessory3TintValue, 3);
            }

            if (unit.accessory4TintValue !== "none") {
                addAccessories(Mechs.accessory, unit.accessory4TintValue, 4);
            }
        }

        Object.entries(Mechs.weapon).forEach((stance) => {
            Mechs.weapon[stance[0]].forEach((weapon) => {
                if (!weapon.disabled && weapon.backSrc) {
                    imageLayersArray.push({
                        src: weapon.backSrc,
                        filter: unit.weaponTintValue,
                        name: "weapon/" + `weapon-${stance[0]}-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (!weapon.disabled && weapon.src) {
                    imageLayersArray.push({
                        src: weapon.src,
                        filter: unit.weaponTintValue,
                        name: "weapon/" + `weapon-${stance[0]}-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        });

        if (Mechs.secondaryWeapon.length) {
                Mechs.secondaryWeapon.forEach((weapon) => {
                    if (!weapon.disabled && weapon.backSrc) {
                        imageLayersArray.push({
                            src: weapon.backSrc,
                            filter: unit.secondaryWeaponTintValue,
                            name: "secondaryWeapon/" + `secondary-weapon-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (!weapon.disabled && weapon.src) {
                        imageLayersArray.push({
                            src: weapon.src,
                            filter: unit.secondaryWeaponTintValue,
                            name: "secondaryWeapon/" + `secondary-weapon-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
        }

        if (Mechs.rearMount.length) {
            Mechs.rearMount.forEach((rearMount) => {
                if (rearMount.backSrc) {
                    imageLayersArray.push({
                        src: rearMount.backSrc,
                        filter: unit.rearMountTintValue,
                        name: "rearMount/" + `rear-mount-${rearMount.label}-back.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (rearMount.src) {
                    imageLayersArray.push({
                        src: rearMount.src,
                        filter: unit.rearMountTintValue,
                        name: "rearMount/" + `rear-mount-${rearMount.label}-mid.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (rearMount.frontSrc) {
                    imageLayersArray.push({
                        src: rearMount.frontSrc,
                        filter: unit.rearMountTintValue,
                        name: "rearMount/" + `rear-mount-${rearMount.label}-front.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (Mechs.legs.length) {
            Mechs.legs.forEach((legs) => {
                if (legs.src) {
                    imageLayersArray.push({
                        src: legs.src,
                        filter: unit.legsTintValue,
                        name: "legs/" + `legs-${legs.label}.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (Mechs.arms.length) {
            Mechs.arms.forEach((arm) => {
                if (arm.isMounted) {
                    if (arm.src["1H"].left) {
                        imageLayersArray.push({
                            src: arm.src["1H"].left,
                            filter: unit.armLeftTintValue,
                            name: "armLeft/" + `arm-mounted-${arm.label}-left.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (arm.src["1H"].right) {
                        imageLayersArray.push({
                            src: arm.src["1H"].right,
                            filter: unit.armRightTintValue,
                            name: "armRight/" + `arm-mounted-${arm.label}-right.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                } else if (!arm.disabled) {
                    Object.entries(arm.src).forEach((stance) => {
                        if (stance[1].skeleton) {
                            imageLayersArray.push({
                                src: stance[1].skeleton,
                                filter: unit.armLeftTintValue,
                                name: "armLeft/" + `arm-${stance[0]}-${arm.label}-skeleton.png`.replaceAll(nameRegex, "_"),
                            });
                        }

                        if (stance[1].left) {
                            imageLayersArray.push({
                                src: stance[1].left,
                                filter: unit.armLeftTintValue,
                                name: "armLeft/" + `arm-${stance[0]}-${arm.label}-left.png`.replaceAll(nameRegex, "_"),
                            });
                        }

                        if (stance[1].right) {
                            imageLayersArray.push({
                                src: stance[1].right,
                                filter: unit.armRightTintValue,
                                name: "armRight/" + `arm-${stance[0]}-${arm.label}-right.png`.replaceAll(nameRegex, "_"),
                            });
                        }
                    });
                }
            });
        }

        if (Mechs.chassis.length) {
            Mechs.chassis.forEach((chassis) => {
                if (chassis.src) {
                    imageLayersArray.push({
                        src: chassis.src,
                        filter: unit.chassisTintValue,
                        name: "chassis/" + `chassis-${chassis.label}.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (Mechs.head.length) {
            Mechs.head.forEach((head) => {
                if (head.src) {
                    imageLayersArray.push({
                        src: head.src,
                        filter: unit.headTintValue,
                        name: "head/" + `head-${head.label}.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All Parts",
            label: `${safeName(name)}`,
        });
        CustomMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }


    async function printCustomPlusMech(unit) {
        let imageLayersArray = [];

        function addAccessories(accessoryList, tintValue, tintIndex) {
            accessoryList.forEach((accessory) => {
                if (accessory.backSrc) {
                    imageLayersArray.push({
                        src: accessory.backSrc,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-back.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (accessory.src) {
                    imageLayersArray.push({
                        src: accessory.src,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-mid.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (accessory.frontSrc) {
                    imageLayersArray.push({
                        src: accessory.frontSrc,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-front.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (accessory.foremostSrc) {
                    imageLayersArray.push({
                        src: accessory.foremostSrc,
                        filter: tintValue,
                        name: `accessory${tintIndex}/` + `accessory-${accessory.label}-tint-${tintIndex}-foremost.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (MechsCustomPlus.accessory.length) {
            addAccessories(MechsCustomPlus.accessory, unit.accessory1TintValue, 1);

            if (unit.accessory2TintValue !== "none") {
                addAccessories(MechsCustomPlus.accessory, unit.accessory2TintValue, 2);
            }

            if (unit.accessory3TintValue !== "none") {
                addAccessories(MechsCustomPlus.accessory, unit.accessory3TintValue, 3);
            }

            if (unit.accessory4TintValue !== "none") {
                addAccessories(MechsCustomPlus.accessory, unit.accessory4TintValue, 4);
            }
        }

        Object.entries(MechsCustomPlus.weapon).forEach((stance) => {
            MechsCustomPlus.weapon[stance[0]].forEach((weapon) => {
                if (!weapon.disabled && weapon.backSrc) {
                    imageLayersArray.push({
                        src: weapon.backSrc,
                        filter: unit.weaponTintValue,
                        name: "weapon/" + `weapon-${stance[0]}-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (!weapon.disabled && weapon.src) {
                    imageLayersArray.push({
                        src: weapon.src,
                        filter: unit.weaponTintValue,
                        name: "weapon/" + `weapon-${stance[0]}-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        });

        if (MechsCustomPlus.secondaryWeapon.length) {
                MechsCustomPlus.secondaryWeapon.forEach((weapon) => {
                    if (!weapon.disabled && weapon.backSrc) {
                        imageLayersArray.push({
                            src: weapon.backSrc,
                            filter: unit.secondaryWeaponTintValue,
                            name: "secondaryWeapon/" + `secondary-weapon-${weapon.label}-back.png`.replaceAll(nameRegex, "_"),
                        });
                    }

                    if (!weapon.disabled && weapon.src) {
                        imageLayersArray.push({
                            src: weapon.src,
                            filter: unit.secondaryWeaponTintValue,
                            name: "secondaryWeapon/" + `secondary-weapon-${weapon.label}-front.png`.replaceAll(nameRegex, "_"),
                        });
                    }
                });
        }

        if (MechsCustomPlus.rearMount.length) {
            MechsCustomPlus.rearMount.forEach((rearMount) => {
                if (rearMount.backSrc) {
                    imageLayersArray.push({
                        src: rearMount.backSrc,
                        filter: unit.rearMountTintValue,
                        name: "rearMount/" + `rear-mount-${rearMount.label}-back.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (rearMount.src) {
                    imageLayersArray.push({
                        src: rearMount.src,
                        filter: unit.rearMountTintValue,
                        name: "rearMount/" + `rear-mount-${rearMount.label}-mid.png`.replaceAll(nameRegex, "_"),
                    });
                }

                if (rearMount.frontSrc) {
                    imageLayersArray.push({
                        src: rearMount.frontSrc,
                        filter: unit.rearMountTintValue,
                        name: "rearMount/" + `rear-mount-${rearMount.label}-front.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (MechsCustomPlus.legs.length) {
            MechsCustomPlus.legs.forEach((legs) => {
                if (legs.src) {
                    imageLayersArray.push({
                        src: legs.src,
                        filter: unit.legsTintValue,
                        name: "legs/" + `legs-${legs.label}.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (MechsCustomPlus.leftArm.length) {
            MechsCustomPlus.leftArm.forEach((arm) => {
                imageLayersArray.push({
                    src: arm.src,
                    filter: unit.armLeftTintValue,
                    name: "armLeft/" + `arm-${arm.label}-left.png`.replaceAll(nameRegex, "_"),
                });
            });
        }

        if (MechsCustomPlus.rightArm.length) {
            MechsCustomPlus.rightArm.forEach((arm) => {
                imageLayersArray.push({
                    src: arm.src,
                    filter: unit.armRightTintValue,
                    name: "armRight/" + `arm-${arm.label}-right.png`.replaceAll(nameRegex, "_"),
                });
            });
        }

        if (MechsCustomPlus.chassis.length) {
            MechsCustomPlus.chassis.forEach((chassis) => {
                if (chassis.src) {
                    imageLayersArray.push({
                        src: chassis.src,
                        filter: unit.chassisTintValue,
                        name: "chassis/" + `chassis-${chassis.label}.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        if (MechsCustomPlus.head.length) {
            MechsCustomPlus.head.forEach((head) => {
                if (head.src) {
                    imageLayersArray.push({
                        src: head.src,
                        filter: unit.headTintValue,
                        name: "head/" + `head-${head.label}.png`.replaceAll(nameRegex, "_"),
                    });
                }
            });
        }

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All Parts",
            label: `${safeName(name)}`,
        });
        CustomPlusMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    async function printBrandedMech(unit) {
        let imageLayersArray = createExportLayerOptionsForAllParts({
            unit,
            brandList: mechBrandedBrandList,
            optionList: brandedOptionList,
            layerList: brandedLayerList,
            layerOrder: brandedLayerOrder,
        });

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });


        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All Parts",
            label: `${safeName(name)}`,
        });
        BrandedMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    async function printNPCMech(unit) {
        let imageLayersArray = createExportLayerOptionsForAllParts({
            unit,
            brandList: mechNPCBrandList,
            optionList: npcOptionList,
            layerList: npcLayerList,
            layerOrder: npcLayerOrder,
        });

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export All Parts",
            label: `${safeName(name)}`,
        });
        NPCMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    const ButtonComponent = isButtonThird ? InterfaceThirdButton : InterfaceButton;

    return (
        <ExportWrapper>
            <ButtonComponent
                className={displayTheme}
                disabled={disabled}
                title={"Export All Possible Mech Parts for this Frame as individual PNGs with currently applied color tints"}
                onClick={disabled || isLoading ? () => {} : () => {
                    if (!isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else {
                        setIsLoading(true);
                        if (mechType === "CUSTOM") {
                            return printCustomMech(unit);
                        } else if (mechType === "CUSTOM-PLUS") {
                            return printCustomPlusMech(unit);
                        } else if (mechType === "NPC") {
                            return printNPCMech(unit);
                        } else {
                            return printBrandedMech(unit);
                        }
                    }
                }}
            >
                {isLoading ? "LOADING" : "⇣ ALL ●●"}
            </ButtonComponent>
            <canvas ref={canvasRef} height={imageSize} width={imageSize} />
        </ExportWrapper>
    );
}

export default ExportAllMechPartsButton;
