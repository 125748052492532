import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Lycan-Loud`;

// BODY
const bodyChassisImage = `${imagePath}/Chassis.png`;
const bodyChassisKicksImage = `${imagePath}/Chassis Kicks.png`;
const bodyChassisTailImage = `${imagePath}/Chassis Tail.png`;

// HEADS
const headLycanImage = `${imagePath}/heads/Head -Lycan.png`;
const headChainsawGuyImage = `${imagePath}/heads/Head - Chainsaw Guy.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNelson2Image = `${imagePath}/heads/Head - Nelson 2.png`;
const headNelsonFlightTypeImage = `${imagePath}/heads/Head - Nelson Flight Type.png`;
const headNelsonImage = `${imagePath}/heads/Head - Nelson.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headRinzlerImage = `${imagePath}/heads/Head - Rinzler.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;
const headVisorImage = `${imagePath}/heads/Head -Visor.png`;

// WEAPONS
const weaponBladesImage = `${imagePath}/weapons/Weapons - Blades.png`;
const weaponChainsawsImage = `${imagePath}/weapons/Weapons - Chainsaws.png`;
const weaponDiscsImage = `${imagePath}/weapons/Weapons - Discs.png`;
const weaponHandsImage = `${imagePath}/weapons/Weapons - Hands.png`;
const weaponShockClawsImage = `${imagePath}/weapons/Weapons - Shock Claws.png`;
const weaponWhipclawHandsImage = `${imagePath}/weapons/Weapons - Whipclaw Hands.png`;

// WEAPON GLOWS
const weaponGlowChainsawsImage = `${imagePath}/glows/Glows 1 - Chainsaws.png`;
const weaponGlowDiscsImage = `${imagePath}/glows/Glows 1 - Discs.png`;
const weaponGlowShockClawsImage = `${imagePath}/glows/Glows 1 - Shock Claws.png`;
const weaponGlowWhipclawsImage = `${imagePath}/glows/Glows 1 - Whipclaws.png`;
const weaponGlowWhipclawsArcsImage = `${imagePath}/glows/Glows 1 - Whipclaws Arcs.png`;
const weaponGlowWhipclawsTailImage = `${imagePath}/glows/Glows 1 - Whipclaws Tail.png`;
const weaponGlowWhipclawsArcsTailImage = `${imagePath}/glows/Glows 1 - Whipclaws Arcs Tail.png`;

// GLOWS
const glowChassisImage = `${imagePath}/glows/Glows 1 - Chassis.png`;
const glowChassisArcsImage = `${imagePath}/glows/Glows 1 - Chassis Arcs.png`;
const glowSpikesImage = `${imagePath}/glows/Glows 1 - Spikes.png`;
const glowChainsawGuyHeadImage = `${imagePath}/glows/Glows 2 - Chainsaw Guy Head.png`;
const glowLycanHeadImage = `${imagePath}/glows/Glows 2 - Lycan Head.png`;
const glowRinzlerHeadImage = `${imagePath}/glows/Glows 2 - Rinzler Head.png`;
const glowScarfImage = `${imagePath}/glows/Glows 1 - Scarf.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image =  `${imagePath}/corebook_lycan_toast.png`;
const core2Image =  `${imagePath}/Lycan_Loud_by_gummies139.png`;
const core3Image =  `${imagePath}/LYCAN-azmilion.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "67px",
    right: "47px",
};

export default {
    isMegadeusPlus: true,
    rearMountsAreChassis: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core3Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "AzMiLion",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "4",
        "accessory1Value": "1",
        "accessory2Value": "4",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LYCAN",
            foremostSrc: headLycanImage,
        },
        {
            value: 1,
            label: "CHAINSAW GUY",
            foremostSrc: headChainsawGuyImage,
        },
        {
            value: 2,
            label: "CHEDDAH",
            foremostSrc: headCheddahImage,
        },
        {
            value: 3,
            label: "CHIMERA",
            foremostSrc: headChimeraImage,
        },
        {
            value: 4,
            label: "CYCLOPS",
            foremostSrc: headCyclopsImage,
        },
        {
            value: 5,
            label: "ENKIDU",
            foremostSrc: headEnkiduImage,
        },
        {
            value: 6,
            label: "HORNED",
            foremostSrc: headHornedImage,
        },
        {
            value: 7,
            label: "HORNED HAIR",
            foremostSrc: headHornedHairImage,
        },
        {
            value: 8,
            label: "LABORER",
            foremostSrc: headLaborerImage,
        },
        {
            value: 9,
            label: "MAG",
            foremostSrc: headMagImage,
        },
        {
            value: 10,
            label: "MALICE",
            foremostSrc: headMaliceImage,
        },
        {
            value: 11,
            label: "MECHA 1",
            foremostSrc: headMecha1Image,
        },
        {
            value: 12,
            label: "MECHA 2",
            foremostSrc: headMecha2Image,
        },
        {
            value: 13,
            label: "MECHA 3",
            foremostSrc: headMecha3Image,
        },
        {
            value: 14,
            label: "MECHA 4",
            foremostSrc: headMecha4Image,
        },
        {
            value: 15,
            label: "MECHA 5",
            foremostSrc: headMecha5Image,
        },
        {
            value: 16,
            label: "MECHA 6",
            foremostSrc: headMecha6Image,
        },
        {
            value: 17,
            label: "MECHA 7",
            foremostSrc: headMecha7Image,
        },
        {
            value: 18,
            label: "MECHA 8",
            foremostSrc: headMecha8Image,
        },
        {
            value: 19,
            label: "NELSON 2",
            foremostSrc: headNelson2Image,
        },
        {
            value: 20,
            label: "NELSON FLIGHT TYPE",
            foremostSrc: headNelsonFlightTypeImage,
        },
        {
            value: 21,
            label: "NELSON",
            foremostSrc: headNelsonImage,
        },
        {
            value: 22,
            label: "NEMESIS",
            foremostSrc: headNemesisImage,
        },
        {
            value: 23,
            label: "RAIN TRANSFORMED",
            foremostSrc: headRainTransformedImage,
        },
        {
            value: 24,
            label: "RAIN TRANSFORMED HAIR",
            foremostSrc: headRainTransformedHairImage,
        },
        {
            value: 25,
            label: "RINZLER",
            foremostSrc: headRinzlerImage,
        },
        {
            value: 26,
            label: "SKULL",
            foremostSrc: headSkullImage,
        },
        {
            value: 27,
            label: "VICEROY",
            foremostSrc: headViceroyImage,
        },
        {
            value: 28,
            label: "VISIONARY",
            foremostSrc: headVisionaryImage,
        },
        {
            value: 29,
            label: "VISOR",
            foremostSrc: headVisorImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "LOUD",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "LYCAN KICKS",
            src: bodyChassisKicksImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 2,
            label: "LYCAN TAIL",
            src: bodyChassisTailImage,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "CHAINSAWS",
                src: weaponGlowChainsawsImage,
            },
            {
                value: 1,
                label: "DISCS",
                src: weaponGlowDiscsImage,
            },
            {
                value: 2,
                label: "SHOCK CLAWS",
                src: weaponGlowShockClawsImage,
            },
            {
                value: 3,
                label: "WHIPCLAWS",
                src: weaponGlowWhipclawsImage,
            },
            {
                value: 4,
                label: "WHIPCLAWS + ARCS",
                src: weaponGlowWhipclawsArcsImage,
            },
            {
                value: 5,
                label: "WHIPCLAWS + TAIL",
                src: weaponGlowWhipclawsTailImage,
            },
            {
                value: 6,
                label: "WHIPCLAWS + ARCS + TAIL",
                src: weaponGlowWhipclawsArcsTailImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "BLADES",
            src: weaponBladesImage,
        },
        {
            value: 1,
            label: "CHAINSAWS",
            src: weaponChainsawsImage,
        },
        {
            value: 2,
            label: "DISCS",
            src: weaponDiscsImage,
        },
        {
            value: 3,
            label: "HANDS",
            src: weaponHandsImage,
        },
        {
            value: 4,
            label: "SHOCK CLAWS",
            src: weaponShockClawsImage,
        },
        {
            value: 5,
            label: "WHIPCLAW HANDS",
            src: weaponWhipclawHandsImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "CHASSIS",
            src: glowChassisImage,
        },
        {
            value: 1,
            label: "CHASSIS + ARCS",
            src: glowChassisArcsImage,
        },
        {
            value: 2,
            label: "SPIKES",
            src: glowSpikesImage,
        },
        {
            value: 3,
            label: "CHAINSAW GUY HEAD",
            foremostSrc: glowChainsawGuyHeadImage,
        },
        {
            value: 4,
            label: "LYCAN HEAD",
            foremostSrc: glowLycanHeadImage,
        },
        {
            value: 5,
            label: "RINZLER HEAD",
            foremostSrc: glowRinzlerHeadImage,
        },
        {
            value: 6,
            label: "ACCESSORY - SCARF",
            src: glowScarfImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON GLOW",
            value: "weapon",
            isGlow: true,
        },
        {
            label: "WEAPONS",
            value: "secondaryWeapon",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WEAPON GLOW",
        "secondaryWeapon": "WEAPONS",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
