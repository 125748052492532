import styled from "styled-components";
import BaseURL from "../../Unit/data/images/Hosting-BaseURL";

// Created via https://www.toptal.com/developers/css/sprite-generator
// -click versions are not used directly
// :active sections use the position of the -click versions

const cssSpritesImage = `${BaseURL}/buttons/css_sprites_2.png`;

const SpriteImage = styled.div`
    background-image: url('${cssSpritesImage}');
    background-repeat: no-repeat;

    &.bg-contact-active {
        width: 86px; height: 19px;
        background-position: -874px -882px;
    }

    &.bg-contact-inactive {
        width: 86px; height: 19px;
        background-position: -10px -969px;
    }

    &.bg-faq-active {
        width: 52px; height: 20px;
        background-position: -1049px -269px;
    }

    &.bg-faq-inactive {
        width: 52px; height: 20px;
        background-position: -1049px -309px;
    }

    &.bg-membership-active {
        width: 127px; height: 18px;
        background-position: -778px -609px;
    }

    &.bg-membership-inactive {
        width: 127px; height: 18px;
        background-position: -778px -647px;
    }

    &.bg-news-active {
        width: 57px; height: 18px;
        background-position: -1049px -193px;
    }

    &.bg-news-inactive {
        width: 57px; height: 18px;
        background-position: -1049px -231px;
    }

    &.bg-orb-discord {
        width: 47px; height: 47px;
        background-position: -982px -10px;
    }

    &.bg-orb-email {
        width: 47px; height: 47px;
        background-position: -982px -77px;
    }

    &.bg-orb-patreon {
        width: 47px; height: 47px;
        background-position: -982px -144px;
    }

    &.bg-orb-roll20 {
        width: 47px; height: 47px;
        background-position: -982px -211px;
    }

    &.bg-orb-twitter {
        width: 47px; height: 47px;
        background-position: -982px -278px;
    }

    &.bg-promo-codes-active {
        width: 144px; height: 19px;
        background-position: -778px -201px;
    }

    &.bg-promo-codes-inactive {
        width: 144px; height: 19px;
        background-position: -778px -240px;
    }

    &.bg-patreon-logout-white {
        width: 101px; height: 19px;
        background-position: -776px -804px;
    }

    &.bg-patreon-login-white {
        width: 97px; height: 19px;
        background-position: -373px -843px;
    }

    &.bg-patreon-logout-gold {
        width: 101px; height: 19px;
        background-position: -10px -843px;
    }

    &.bg-tabletop-minis-logo-red {
        width: 181px; height: 17px;
        background-position: -778px -49px;
    }

    &.bg-0 {
        width: 18px; height: 18px;
        background-position: -982px -422px;
    }

    &.bg-1 {
        width: 18px; height: 18px;
        background-position: -982px -460px;
    }

    &.bg-2 {
        width: 18px; height: 18px;
        background-position: -982px -498px;
    }

    &.bg-3 {
        width: 18px; height: 18px;
        background-position: -982px -536px;
    }

    &.bg-4 {
        width: 18px; height: 18px;
        background-position: -982px -574px;
    }

    &.bg-5 {
        width: 18px; height: 18px;
        background-position: -982px -612px;
    }

    &.bg-6 {
        width: 18px; height: 18px;
        background-position: -982px -650px;
    }

    &.bg-7 {
        width: 18px; height: 18px;
        background-position: -982px -688px;
    }

    &.bg-8 {
        width: 18px; height: 18px;
        background-position: -982px -726px;
    }

    &.bg-9 {
        width: 18px; height: 18px;
        background-position: -982px -764px;
    }

    &.bg-10 {
        width: 24px; height: 18px;
        background-position: -1095px -896px;
    }

    &.bg-11 {
        width: 24px; height: 18px;
        background-position: -1090px -934px;
    }

    &.bg-12 {
        width: 26px; height: 18px;
        background-position: -1049px -896px;
    }

    &.bg-logo-shadow {
        width: 478px; height: 126px;
        background-position: -280px -10px;
    }

    &.bg-max12 {
        width: 41px; height: 18px;
        background-position: -1049px -692px;
    }

    &.bg-retrograde-logo {
        width: 478px; height: 126px;
        background-position: -10px -280px;
    }

    &.bg-retrograde-logo-red {
        width: 478px; height: 126px;
        background-position: -10px -426px;
    }

    &.bg-tabletop-minis-logo {
        width: 181px; height: 17px;
        background-position: -778px -86px;
    }

    &.bg-print-inactive {
        width: 78px; height: 28px;
        background-position: -10px -882px;
    }

    &.bg-random-active {
        width: 96px; height: 28px;
        background-position: -778px -685px;

        :active {
            width: 96px; height: 28px;
            background-position: -10px -756px;
        }
    }

    &.bg-random-click {
        width: 96px; height: 28px;
        background-position: -10px -756px;
    }

    &.bg-random-inactive {
        width: 96px; height: 28px;
        background-position: -126px -756px;

        :active {
            width: 96px; height: 28px;
            background-position: -10px -756px;
        }
    }

    &.bg-r-arrow {
        width: 14px; height: 20px;
        background-position: -907px -279px;
    }

    &.bg-scale-button-1-5-active {
        width: 29px; height: 21px;
        background-position: -1049px -730px;
    }

    &.bg-scale-button-1-5-inactive {
        width: 29px; height: 21px;
        background-position: -1049px -771px;
    }

    &.bg-scale-button-1-active {
        width: 24px; height: 21px;
        background-position: -1098px -730px;
    }

    &.bg-scale-button-1-inactive {
        width: 24px; height: 21px;
        background-position: -1098px -771px;
    }

    &.bg-scale-button-2-active {
        width: 26px; height: 21px;
        background-position: -1049px -855px;
    }

    &.bg-scale-button-2-inactive {
        width: 26px; height: 21px;
        background-position: -1095px -855px;
    }

    &.bg-sfx-off {
        width: 76px; height: 13px;
        background-position: -363px -719px;
    }

    &.bg-sfx-on {
        width: 67px; height: 13px;
        background-position: -1049px -349px;
    }

    &.bg-unit-outline-active {
        width: 190px; height: 28px;
        background-position: -508px -376px;

        :active {
            width: 190px; height: 28px;
            background-position: -508px -426px;
        }
    }

    &.bg-unit-outline-inactive {
        width: 190px; height: 28px;
        background-position: -508px -474px;

        :active {
            width: 190px; height: 28px;
            background-position: -508px -426px;
        }
    }

    &.bg-unit-outline-click {
        width: 190px; height: 28px;
        background-position: -508px -426px;
    }

    &.bg-unit-selected-border {
        width: 105px; height: 127px;
        background-position: -10px -572px;
    }

    &.bg-unlocked {
        width: 15px; height: 15px;
        background-position: -931px -453px;
    }

    &.bg-variant-active {
        width: 10px; height: 10px;
        background-position: -1110px -692px;
    }

    &.bg-variant-inactive {
        width: 10px; height: 10px;
        background-position: -1089px -1013px;
    }

    &.bg-x-button {
        width: 19px; height: 18px;
        background-position: -982px -384px;
    }

    &.bg-back-arrow-white {
        width: 15px; height: 15px;
        background-position: -931px -492px;
    }

    &.bg-background-label {
        width: 122px; height: 13px;
        background-position: -616px -156px;
    }

    &.bg-black-bg-active {
        width: 190px; height: 28px;
        background-position: -508px -522px;

        :active {
            width: 190px; height: 28px;
            background-position: -135px -572px;
        }
    }

    &.bg-black-bg-click {
        width: 190px; height: 28px;
        background-position: -135px -572px;
    }

    &.bg-black-bg-inactive {
        width: 190px; height: 28px;
        background-position: -345px -572px;

        :active {
            width: 190px; height: 28px;
            background-position: -135px -572px;
        }
    }

    &.bg-clear-all-click {
        width: 109px; height: 28px;
        background-position: -778px -279px;
    }

    &.bg-clear-all-inactive {
        width: 109px; height: 28px;
        background-position: -778px -327px;

        :active {
            width: 109px; height: 28px;
            background-position: -778px -279px;
        }
    }

    &.bg-click-to-start {
        width: 236px; height: 20px;
        background-position: -280px -233px;
    }

    &.bg-click-to-start-shadow {
        width: 233px; height: 17px;
        background-position: -10px -719px;
    }

    &.bg-Color-Sample-Square-18 {
        width: 18px; height: 18px;
        background-position: -982px -802px;
    }

    &.bg-Color-Sample-Square-Glow-18 {
        width: 18px; height: 18px;
        background-position: -982px -840px;
    }

    &.bg-d-arrow {
        width: 20px; height: 14px;
        background-position: -1049px -1013px;
    }

    &.bg-double-sided-active {
        width: 190px; height: 28px;
        background-position: -555px -572px;

        :active {
            width: 190px; height: 28px;
            background-position: -345px -620px;
        }
    }

    &.bg-double-sided-inactive {
        width: 190px; height: 28px;
        background-position: -135px -620px;

        :active {
            width: 190px; height: 28px;
            background-position: -345px -620px;
        }
    }

    &.bg-double-sided-click {
        width: 190px; height: 28px;
        background-position: -345px -620px;
    }

    &.bg-download-button {
        width: 24px; height: 23px;
        background-position: -1049px -812px;
    }

    &.bg-fantasyActive {
        width: 194px; height: 28px;
        background-position: -508px -280px;
    }

    &.bg-gm-sheet-active {
        width: 190px; height: 28px;
        background-position: -555px -620px;

        :active {
            width: 190px; height: 28px;
            background-position: -345px -668px;
        }
    }

    &.bg-gm-sheet-inactive {
        width: 190px; height: 28px;
        background-position: -135px -668px;

        :active {
            width: 190px; height: 28px;
            background-position: -345px -668px;
        }
    }

    &.bg-gm-sheet-click {
        width: 190px; height: 28px;
        background-position: -345px -668px;
    }

    &.bg-hide-ui {
        width: 71px; height: 13px;
        background-position: -1049px -127px;
    }

    &.bg-l-arrow {
        width: 14px; height: 20px;
        background-position: -941px -279px;
    }

    &.bg-legendary-membership {
        width: 316px; height: 18px;
        background-position: -280px -156px;
    }

    &.bg-locked {
        width: 15px; height: 15px;
        background-position: -927px -531px;
    }

    &.bg-mechsActive {
        width: 194px; height: 28px;
        background-position: -508px -328px;
    }

    &.bg-mundane-membership {
        width: 300px; height: 19px;
        background-position: -280px -194px;
    }

    &.bg-music-next {
        width: 15px; height: 15px;
        background-position: -927px -570px;
    }

    &.bg-music-off {
        width: 80px; height: 13px;
        background-position: -263px -719px;
    }

    &.bg-music-on {
        width: 71px; height: 13px;
        background-position: -1049px -160px;
    }

    &.bg-music-prev {
        width: 15px; height: 15px;
        background-position: -925px -609px;
    }

    &.bg-orb-blue {
        width: 16px; height: 16px;
        background-position: -942px -201px;
    }

    &.bg-orb-green {
        width: 16px; height: 16px;
        background-position: -942px -240px;
    }

    &.bg-orb-purple {
        width: 16px; height: 16px;
        background-position: -907px -327px;
    }

    &.bg-orb-red {
        width: 16px; height: 16px;
        background-position: -943px -327px;
    }

    &.bg-orb-unlit {
        width: 16px; height: 16px;
        background-position: -931px -375px;
    }

    &.bg-orb-yellow {
        width: 16px; height: 16px;
        background-position: -931px -414px;
    }

    &.bg-plus-button {
        width: 24px; height: 23px;
        background-position: -1093px -812px;
    }

    &.bg-plus-one-membership {
        width: 202px; height: 21px;
        background-position: -555px -668px;
    }

    &.bg-print-click {
        width: 78px; height: 28px;
        background-position: -108px -882px;
    }

    &.bg-bbegs-active {
        width: 85px; height: 19px;
        background-position: -116px -969px;
    }

    &.bg-bbegs-inactive {
        width: 85px; height: 19px;
        background-position: -221px -969px;
    }

    &.bg-eldritch-active {
        width: 101px; height: 19px;
        background-position: -131px -843px;
    }

    &.bg-eldritch-inactive {
        width: 101px; height: 19px;
        background-position: -252px -843px;
    }

    &.bg-martial-active {
        width: 87px; height: 18px;
        background-position: -446px -930px;
    }

    &.bg-martial-inactive {
        width: 87px; height: 18px;
        background-position: -553px -930px;
    }

    &.bg-orcs-active {
        width: 53px; height: 19px;
        background-position: -1049px -382px;
    }

    &.bg-orcs-inactive {
        width: 53px; height: 19px;
        background-position: -1049px -421px;
    }

    &.bg-kobolds-active {
        width: 91px; height: 19px;
        background-position: -430px -882px;
    }

    &.bg-kobolds-inactive {
        width: 91px; height: 19px;
        background-position: -541px -882px;
    }

    &.bg-knights-active {
        width: 89px; height: 19px;
        background-position: -10px -930px;
    }

    &.bg-knights-inactive {
        width: 89px; height: 19px;
        background-position: -119px -930px;
    }

    &.bg-insectoid-active {
        width: 103px; height: 19px;
        background-position: -530px -804px;
    }

    &.bg-insectoid-inactive {
        width: 103px; height: 19px;
        background-position: -653px -804px;
    }

    &.bg-beekin-active {
        width: 91px; height: 19px;
        background-position: -652px -882px;
    }

    &.bg-beekin-inactive {
        width: 91px; height: 19px;
        background-position: -763px -882px;
    }

    &.bg-humans-inactive {
        width: 83px; height: 19px;
        background-position: -744px -969px;
    }

    &.bg-humans-active {
        width: 83px; height: 19px;
        background-position: -847px -969px;
    }

    &.bg-guards-inactive {
        width: 77px; height: 19px;
        background-position: -509px -1008px;
    }

    &.bg-guards-active {
        width: 77px; height: 19px;
        background-position: -606px -1008px;
    }

    &.bg-goblins-inactive {
        width: 85px; height: 19px;
        background-position: -326px -969px;
    }

    &.bg-goblins-active {
        width: 85px; height: 19px;
        background-position: -431px -969px;
    }

    &.bg-fishfolk-inactive {
        width: 95px; height: 19px;
        background-position: -490px -843px;
    }

    &.bg-fishfolk-active {
        width: 95px; height: 19px;
        background-position: -605px -843px;
    }

    &.bg-doggefolk-inactive {
        width: 111px; height: 19px;
        background-position: -10px -804px;
    }

    &.bg-doggefolk-active {
        width: 111px; height: 19px;
        background-position: -141px -804px;
    }

    &.bg-cultists-inactive {
        width: 92px; height: 19px;
        background-position: -720px -843px;
    }

    &.bg-cultists-active {
        width: 92px; height: 19px;
        background-position: -832px -843px;
    }

    &.bg-natural-active {
        width: 89px; height: 18px;
        background-position: -228px -930px;
    }

    &.bg-natural-inactive {
        width: 89px; height: 18px;
        background-position: -337px -930px;
    }

    &.bg-plants-inactive {
        width: 78px; height: 19px;
        background-position: -313px -1008px;
    }

    &.bg-plants-active {
        width: 78px; height: 19px;
        background-position: -411px -1008px;
    }

    &.bg-fantastical-active {
        width: 133px; height: 19px;
        background-position: -778px -375px;
    }

    &.bg-fantastical-inactive {
        width: 133px; height: 19px;
        background-position: -778px -414px;
    }

    &.bg-creatures-active {
        width: 114px; height: 19px;
        background-position: -651px -756px;
    }

    &.bg-creatures-inactive {
        width: 114px; height: 19px;
        background-position: -785px -756px;
    }

    &.bg-elemental-inactive {
        width: 119px; height: 19px;
        background-position: -600px -194px;
    }

    &.bg-elemental-active {
        width: 119px; height: 19px;
        background-position: -242px -756px;
    }

    &.bg-bugs-inactive {
        width: 52px; height: 19px;
        background-position: -1049px -460px;
    }

    &.bg-bugs-active {
        width: 52px; height: 19px;
        background-position: -1049px -499px;
    }

    &.bg-animals-inactive {
        width: 79px; height: 19px;
        background-position: -950px -969px;
    }

    &.bg-animals-active {
        width: 79px; height: 19px;
        background-position: -214px -1008px;
    }

    &.bg-special-active {
        width: 84px; height: 19px;
        background-position: -536px -969px;
    }

    &.bg-special-inactive {
        width: 84px; height: 19px;
        background-position: -640px -969px;
    }

    &.bg-treshornyboys-inactive {
        width: 184px; height: 19px;
        background-position: -536px -233px;
    }

    &.bg-treshornyboys-active {
        width: 184px; height: 19px;
        background-position: -778px -10px;
    }

    &.bg-runesmith-active {
        width: 115px; height: 19px;
        background-position: -381px -756px;
    }

    &.bg-runesmith-inactive {
        width: 115px; height: 19px;
        background-position: -516px -756px;
    }

    &.bg-pumpkinfolk-inactive {
        width: 129px; height: 19px;
        background-position: -778px -531px;
    }

    &.bg-pumpkinfolk-active {
        width: 129px; height: 19px;
        background-position: -778px -570px;
    }

    &.bg-christmas-inactive {
        width: 109px; height: 19px;
        background-position: -272px -804px;
    }

    &.bg-christmas-active {
        width: 109px; height: 19px;
        background-position: -401px -804px;
    }

    &.bg-summons-inactive {
        width: 92px; height: 19px;
        background-position: -206px -882px;
    }

    &.bg-summons-active {
        width: 92px; height: 19px;
        background-position: -318px -882px;
    }

    &.bg-the-tavern-inactive {
        width: 133px; height: 19px;
        background-position: -778px -453px;
    }

    &.bg-the-tavern-active {
        width: 133px; height: 19px;
        background-position: -778px -492px;
    }

    &.bg-undead-inactive {
        width: 82px; height: 19px;
        background-position: -10px -1008px;
    }

    &.bg-undead-active {
        width: 82px; height: 19px;
        background-position: -112px -1008px;
    }

    &.bg-new-inactive {
        width: 48px; height: 18px;
        background-position: -1049px -616px;
    }

    &.bg-tags-active {
        width: 49px; height: 19px;
        background-position: -1049px -538px;
    }

    &.bg-tags-inactive {
        width: 49px; height: 19px;
        background-position: -1049px -577px;
    }

    &.bg-new-active {
        width: 48px; height: 18px;
        background-position: -1049px -654px;
    }

    &.bg-ha-logo {
        width: 19px; height: 17px;
        background-position: -982px -878px;
    }

    &.bg-horus-logo {
        width: 21px; height: 18px;
        background-position: -1049px -975px;
    }

    &.bg-ips-logo {
        width: 19px; height: 19px;
        background-position: -982px -345px;
    }

    &.bg-ssc-logo {
        width: 21px; height: 21px;
        background-position: -1049px -934px;
    }

    &.bg-gms-logo {
        width: 20px; height: 18px;
        background-position: -1090px -975px;
    }

    &.bg-custom-active {
        width: 76px; height: 19px;
        background-position: -703px -1008px;
    }

    &.bg-custom-inactive {
        width: 76px; height: 19px;
        background-position: -799px -1008px;
    }

    &.bg-icon-inactive {
        width: 63px; height: 19px;
        background-position: -1049px -49px;
    }

    &.bg-icon-active {
        width: 63px; height: 19px;
        background-position: -1049px -88px;
    }

    &.bg-relict-inactive {
        width: 74px; height: 19px;
        background-position: -895px -1008px;
    }

    &.bg-relict-active {
        width: 74px; height: 19px;
        background-position: -1049px -10px;
    }

    &.bg-relict-tokens-active {
        width: 156px; height: 19px;
        background-position: -778px -123px;
    }

    &.bg-relict-tokens-inactive {
        width: 156px; height: 19px;
        background-position: -778px -162px;
    }

    &.bg-warning {
        width: 250px; height: 250px;
        background-position: -10px -10px;
    }
`;

SpriteImage.displayName = "SpriteImage";

export default SpriteImage;

export const SPRITE_KEYS = {
    // Website Logos
    retrogradeLogo: "bg-retrograde-logo",
    tabletopMinisLogo: "bg-tabletop-minis-logo",
    retrogradeLogoRed: "bg-retrograde-logo-red",
    tabletopMinisLogoRed: "bg-tabletop-minis-logo-red",
    logoShadow: "bg-logo-shadow",
    clickToStart: "bg-click-to-start",
    clickToStartShadow: "bg-click-to-start-shadow",

    // Menu Titles
    contactActive: "bg-contact-active",
    contactInactive: "bg-contact-inactive",
    faqActive: "bg-faq-active",
    faqInactive: "bg-faq-inactive",
    membershipActive: "bg-membership-active",
    membershipInactive: "bg-membership-inactive",
    newsActive: "bg-news-active",
    newsInactive: "bg-news-inactive",
    promoCodesActive: "bg-promo-codes-active",
    promoCodesInactive: "bg-promo-codes-inactive",
    patreonLogoutWhite: "bg-patreon-logout-white",
    patreonLoginWhite: "bg-patreon-login-white",
    patreonLogoutGold: "bg-patreon-logout-gold",

    // Membership icons
    mundaneMembership: "bg-mundane-membership",
    plusOneMembership: "bg-plus-one-membership",
    legendaryMembership: "bg-legendary-membership",

    // Orbs
    orbDiscord: "bg-orb-discord",
    orbEmail: "bg-orb-email",
    orbPatreon: "bg-orb-patreon",
    orbRoll20: "bg-orb-roll20",
    orbTwitter: "bg-orb-twitter",
    orbBlue: "bg-orb-blue",
    orbGreen: "bg-orb-green",
    orbPurple: "bg-orb-purple",
    orbRed: "bg-orb-red",
    orbUnlit: "bg-orb-unlit",
    orbYellow: "bg-orb-yellow",

    // Lancer Logos
    haLogo: "bg-ha-logo",
    horusLogo: "bg-horus-logo",
    ipsLogo: "bg-ips-logo",
    sscLogo: "bg-ssc-logo",
    gmsLogo: "bg-gms-logo",

    // UI Buttons
    num0: "bg-0",
    num1: "bg-1",
    num2: "bg-2",
    num3: "bg-3",
    num4: "bg-4",
    num5: "bg-5",
    num6: "bg-6",
    num7: "bg-7",
    num8: "bg-8",
    num9: "bg-9",
    num10: "bg-10",
    num11: "bg-11",
    num12: "bg-12",
    max12: "bg-max12",
    printInactive: "bg-print-inactive",
    randomActive: "bg-random-active",
    randomClick: "bg-random-click",
    randomInactive: "bg-random-inactive",
    rArrow: "bg-r-arrow",
    scaleButton15Active: "bg-scale-button-1-5-active",
    scaleButton15Inactive: "bg-scale-button-1-5-inactive",
    scaleButton1Active: "bg-scale-button-1-active",
    scaleButton1Inactive: "bg-scale-button-1-inactive",
    scaleButton2Active: "bg-scale-button-2-active",
    scaleButton2Inactive: "bg-scale-button-2-inactive",
    sfxOff: "bg-sfx-off",
    sfxOn: "bg-sfx-on",
    unitOutlineActive: "bg-unit-outline-active",
    unitOutlineInactive: "bg-unit-outline-inactive",
    unitSelectedBorder: "bg-unit-selected-border",
    variantActive: "bg-variant-active",
    variantInactive: "bg-variant-inactive",
    xButton: "bg-x-button",
    backArrowWhite: "bg-back-arrow-white",
    backgroundLabel: "bg-background-label",
    blackBgActive: "bg-black-bg-active",
    blackBgClick: "bg-black-bg-click",
    blackBgInactive: "bg-black-bg-inactive",
    clearAllClick: "bg-clear-all-click",
    clearAllInactive: "bg-clear-all-inactive",
    colorSampleSquare18: "bg-Color-Sample-Square-18",
    colorSampleSquareGlow18: "bg-Color-Sample-Square-Glow-18",
    dArrow: "bg-d-arrow",
    doubleSidedActive: "bg-double-sided-active",
    doubleSidedInactive: "bg-double-sided-inactive",
    downloadButton: "bg-download-button",
    fantasyActive: "bg-fantasyActive",
    gSheetActive: "bg-gm-sheet-active",
    gSheetInactive: "bg-gm-sheet-inactive",
    hideUi: "bg-hide-ui",
    lArrow: "bg-l-arrow",
    locked: "bg-locked",
    unlocked: "bg-unlocked",
    mechsActive: "bg-mechsActive",
    musicNext: "bg-music-next",
    musicOff: "bg-music-off",
    musicOn: "bg-music-on",
    musicPrev: "bg-music-prev",
    plusButton: "bg-plus-button",
    printClick: "bg-print-click",
    warning: "bg-warning",

    // Unit Categories
    newActive: "bg-new-active",
    newInactive: "bg-new-inactive",
    tagsActive: "bg-tags-active",
    tagsInactive: "bg-tags-inactive",
    customActive: "bg-custom-active",
    customInactive: "bg-custom-inactive",
    bbegsActive: "bg-bbegs-active",
    bbegsInactive: "bg-bbegs-inactive",
    eldritchInactive: "bg-eldritch-inactive",
    eldritchActive: "bg-eldritch-active",
    martialActive: "bg-martial-active",
    martialInactive: "bg-martial-inactive",
    orcsInactive: "bg-orcs-inactive",
    orcsActive: "bg-orcs-active",
    koboldsActive: "bg-kobolds-active",
    koboldsInactive: "bg-kobolds-inactive",
    knightsInactive: "bg-knights-inactive",
    knightsActive: "bg-knights-active",
    insectoidActive: "bg-insectoid-active",
    insectoidInactive: "bg-insectoid-inactive",
    beekinActive: "bg-beekin-active",
    beekinInactive: "bg-beekin-inactive",
    humansInactive: "bg-humans-inactive",
    humansActive: "bg-humans-active",
    guardsInactive: "bg-guards-inactive",
    guardsActive: "bg-guards-active",
    goblinsInactive: "bg-goblins-inactive",
    goblinsActive: "bg-goblins-active",
    fishfolkInactive: "bg-fishfolk-inactive",
    fishfolkActive: "bg-fishfolk-active",
    doggefolkInactive: "bg-doggefolk-inactive",
    doggefolkActive: "bg-doggefolk-active",
    cultistsInactive: "bg-cultists-inactive",
    cultistsActive: "bg-cultists-active",
    naturalActive: "bg-natural-active",
    naturalInactive: "bg-natural-inactive",
    plantsInactive: "bg-plants-inactive",
    plantsActive: "bg-plants-active",
    fantasticalActive: "bg-fantastical-active",
    fantasticalInactive: "bg-fantastical-inactive",
    creaturesActive: "bg-creatures-active",
    creaturesInactive: "bg-creatures-inactive",
    elementalInactive: "bg-elemental-inactive",
    elementalActive: "bg-elemental-active",
    bugsInactive: "bg-bugs-inactive",
    bugsActive: "bg-bugs-active",
    animalsInactive: "bg-animals-inactive",
    animalsActive: "bg-animals-active",
    specialActive: "bg-special-active",
    specialInactive: "bg-special-inactive",
    treshornyboysInactive: "bg-treshornyboys-inactive",
    treshornyboysActive: "bg-treshornyboys-active",
    runesmithActive: "bg-runesmith-active",
    runesmithInactive: "bg-runesmith-inactive",
    pumpkinfolkInactive: "bg-pumpkinfolk-inactive",
    pumpkinfolkActive: "bg-pumpkinfolk-active",
    christmasInactive: "bg-christmas-inactive",
    christmasActive: "bg-christmas-active",
    summonsInactive: "bg-summons-inactive",
    summonsActive: "bg-summons-active",
    theTavernInactive: "bg-the-tavern-inactive",
    theTavernActive: "bg-the-tavern-active",
    undeadInactive: "bg-undead-inactive",
    undeadActive: "bg-undead-active",
    iconInactive: "bg-icon-inactive",
    iconActive: "bg-icon-active",
    relictInactive: "bg-relict-inactive",
    relictActive: "bg-relict-active",
    relictTokensActive: "bg-relict-tokens-active",
    relictTokensInactive: "bg-relict-tokens-inactive",
}
