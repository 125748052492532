import { shadowDetailsNone } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/Cover`;

const wallImage = `${BaseURL}/Mechs/NPC/Corpro/Barricade/glow/Aux - Wall.png`;
const wallGlowImage = `${BaseURL}/Mechs/NPC/Corpro/Barricade/glow/Aux - Wall glow.png`;
const bodyBioBarricadeCoverImage = `${imagePath}/Misc - Bio Barricade Cover.png`;
const bodyBioBarricadeCoverGlowImage = `${imagePath}/Misc - Bio Barricade Cover Glow.png`;

// BODY
const bodyJerichoCoverNEImage = `${imagePath}/Jericho Deployable Cover - NE.png`;
const bodyJerichoCoverSEImage = `${imagePath}/Jericho Deployable Cover - SE.png`;

const previewImage = `${imagePath}/preview.png`;

const deployableShadow = shadowDetailsNone;

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "CORPRO BARRICADE - WALL",
            src: wallGlowImage,
        },
        {
            value: 1,
            label: "BIOMECHA BARRICADE - WALL",
            src: bodyBioBarricadeCoverGlowImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "JERICHO DEPLOYABLE COVER - NE",
            src: bodyJerichoCoverNEImage,
            shadowDetails: deployableShadow,
        },
        {
            value: 1,
            label: "JERICHO DEPLOYABLE COVER - SE",
            src: bodyJerichoCoverSEImage,
            shadowDetails: deployableShadow,
        },
        {
            value: 2,
            label: "CORPRO BARRICADE - WALL",
            src: wallImage,
            shadowDetails: deployableShadow,
        },
        {
            value: 3,
            label: "BIOMECHA BARRICADE - WALL",
            src: bodyBioBarricadeCoverImage,
            shadowDetails: deployableShadow,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "COVER",
            value: "chassis",
        },
        {
            label: "GLOW",
            value: "head",
            isGlow: true,
        },
    ],
    labels: {
        "head": "GLOW",
        "chassis": "COVER",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "EMPTY",
        "accessory2": "EMPTY",
        "accessory3": "EMPTY",
        "accessory4": "EMPTY",
    },
};
