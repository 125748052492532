import { LAYERS, OPTIONS } from "./Layers";

import ipsPartList from "./../data/Mechs-IPS";
import horusPartList from "./../data/Mechs-Horus";
import sscPartList from "./../data/Mechs-SSC";
import haPartList from "./../data/Mechs-HA";
import retrogradeMechsPartList from "../data/Mechs-Retrograde";
import homebrewMechsPartList from "../data/Mechs-Homebrew-MFR";
import miscMechsPartList from "./../data/Mechs-Misc";
import wallflowerCustomMechsPartList from "./../data/Mechs-Wallflower-Custom";
import kaijuPartList from "./../data/Mechs-Kaiju";

export const brandList = {
    "IPS-N": ipsPartList,
    "Horus": horusPartList,
    "SSC": sscPartList,
    "HA": haPartList,
    "Misc": miscMechsPartList,
    "Wallflower Custom": wallflowerCustomMechsPartList,
    "Kaiju": kaijuPartList,
    "Retrograde": retrogradeMechsPartList,
    "Homebrew MFR": homebrewMechsPartList,
};

export const brandedOptionList = [
    OPTIONS.head,
    OPTIONS.chassis,
    OPTIONS.rearMount,
    OPTIONS.weapon,
    OPTIONS.secondaryWeapon,
    OPTIONS.accessory,
];

export const brandedLayerList = [
    LAYERS.foremostSrc,
    LAYERS.frontSrc,
    LAYERS.midSrc,
    LAYERS.src,
    LAYERS.backSrc,
    LAYERS.backmostSrc,
];

export const brandedLayerOrder = [
    {
        name: "headValue",
        slot: OPTIONS.head,
        tintValue: "headTintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 125,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 90,
            },
            {
                name: LAYERS.src,
                zIndex: 80,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 50,
            },
        ],
    },
    {
        name: "weaponValue",
        slot: OPTIONS.weapon,
        tintValue: "weaponTintValue",
        stanceValue: "stanceValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 140,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 115,
            },
            {
                name: LAYERS.src,
                zIndex: 105,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 35,
            },
            {
                name: LAYERS.backmostSrc,
                zIndex: 5,
            },
        ],
    },
    {
        name: "secondaryWeaponValue",
        slot: OPTIONS.secondaryWeapon,
        tintValue: "secondaryWeaponTintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 135,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 110,
            },
            {
                name: LAYERS.src,
                zIndex: 100,
            },
            {
                name: LAYERS.midSrc,
                zIndex: 65,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 30,
            },
        ],
    },
    {
        name: "chassisValue",
        slot: OPTIONS.chassis,
        tintValue: "chassisTintValue",
        layers: [
            {
                name: LAYERS.frontSrc,
                zIndex: 95,
                rules: [
                    {
                        slot: OPTIONS.head,
                        value: "headValue",
                        check: "removeBodyCover",
                        result: "remove",
                    },
                    {
                        slot: OPTIONS.weapon,
                        stanceValue: "stanceValue",
                        value: "weaponValue",
                        check: "removeBodyCover",
                        result: "remove",
                    },
                ],
            },
            {
                name: LAYERS.midSrc,
                zIndex: 65,
            },
            {
                name: LAYERS.src,
                zIndex: 60,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 15,
            },
        ],
    },
    {
        name: "rearMountValue",
        slot: OPTIONS.rearMount,
        tintValue: "rearMountTintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 130,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 70,
            },
            {
                name: LAYERS.src,
                zIndex: 40,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 25,
            },
            {
                name: LAYERS.backmostSrc,
                zIndex: 4,
            },
        ],
    },
    {
        name: "accessory1Value",
        number: 1,
        slot: OPTIONS.accessory,
        tintValue: "accessory1TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 148,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 123,
            },
            {
                name: LAYERS.src,
                zIndex: 74,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 23,
            },
            {
                name: LAYERS.backmostSrc,
                zIndex: 13,
            },
        ],
    },
    {
        name: "accessory2Value",
        number: 2,
        slot: OPTIONS.accessory,
        tintValue: "accessory2TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 147,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 122,
            },
            {
                name: LAYERS.src,
                zIndex: 73,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 22,
            },
            {
                name: LAYERS.backmostSrc,
                zIndex: 12,
            },
        ],
    },
    {
        name: "accessory3Value",
        number: 3,
        slot: OPTIONS.accessory,
        tintValue: "accessory3TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 146,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 121,
            },
            {
                name: LAYERS.src,
                zIndex: 72,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 21,
            },
            {
                name: LAYERS.backmostSrc,
                zIndex: 11,
            },
        ],
    },
    {
        name: "accessory4Value",
        number: 4,
        slot: OPTIONS.accessory,
        tintValue: "accessory4TintValue",
        layers: [
            {
                name: LAYERS.foremostSrc,
                zIndex: 145,
            },
            {
                name: LAYERS.frontSrc,
                zIndex: 120,
            },
            {
                name: LAYERS.src,
                zIndex: 71,
            },
            {
                name: LAYERS.backSrc,
                zIndex: 20,
            },
            {
                name: LAYERS.backmostSrc,
                zIndex: 10,
            },
        ],
    },
];
