import infantry5 from "./Mechs-Infantry-5";
import infantry10 from "./Mechs-Infantry-10";
import turrets from "./Mechs-Turrets";
import cover from "./Mechs-Cover";
import pilot from "./Mechs-Pilot";
import pilot2 from "./Mechs-Pilot-2";
import naniteCloud from "./Mechs-Nanite-Cloud";
import truck from "./Mechs-Truck";
import wrecks from "./Mechs-Wrecks";
import drones from "./Mechs-Drones-2";
import dropship from "./Mechs-Dropship";
import pitoloklo from "./Mechs-Pitoloklo";
import tank from "./Mechs-Tank";
import dawnbreaker from "./Mechs-Dawnbreaker";
import omnifighter from "./Mechs-Strix-Class-Omnifighter";
import MechsHeavyAttackChopper from "./Mechs-Heavy-Attack-Chopper";
import MechsHeavyTransportChopper from "./Mechs-Heavy-Transport-Chopper";
import MechsMBT from "./Mechs-MBT";

export default [
    Object.assign({ value: 0, label: "COVER" }, cover),
    Object.assign({ value: 1, label: "DAWNBREAKER", isPremium: true, isLegendary: true }, dawnbreaker),
    Object.assign({ value: 2, label: "DRONES" }, drones),
    Object.assign({ value: 3, label: "DROPSHIP" }, dropship),
    Object.assign({ value: 4, label: "HEAVY ATTACK CHOPPER" }, MechsHeavyAttackChopper),
    Object.assign({ value: 5, label: "HEAVY TRANSPORT CHOPPER" }, MechsHeavyTransportChopper),
    Object.assign({ value: 6, label: "INFANTRY - 5" }, infantry5),
    Object.assign({ value: 7, label: "INFANTRY - 10" }, infantry10),
    Object.assign({ value: 8, label: "MBT" }, MechsMBT),
    Object.assign({ value: 9, label: "NANITE CLOUD" }, naniteCloud),
    Object.assign({ value: 10, label: "PILOT 1" }, pilot),
    Object.assign({ value: 11, label: "PILOT 2" }, pilot2),
    Object.assign({ value: 12, label: "PITOLOKLO" }, pitoloklo),
    Object.assign({ value: 13, label: "STRIX CLASS OMNIFIGHTER", isPremium: true, isLegendary: true }, omnifighter),
    Object.assign({ value: 14, label: "TANK" }, tank),
    Object.assign({ value: 15, label: "TRUCK" }, truck),
    Object.assign({ value: 16, label: "TURRETS" }, turrets),
    Object.assign({ value: 17, label: "WRECKS" }, wrecks),
];
