import balor from "./Mechs-Balor";
import balorAggregant from "./Mechs-Balor-Aggregant";
import goblin from "./Mechs-Goblin";
import goblin2 from "./Mechs-Goblin-2";
import flea from "./Mechs-Flea";
import hydra from "./Mechs-Hydra";
import drones from "./Mechs-Drones";
import pegasus from "./Mechs-Pegasus";
import lich from "./Mechs-Lich";
import manticore from "./Mechs-Manticore";
import castigated from "./Mechs-Castigated";
import minotaur from "./Mechs-Minotaur";
import gorgon from "./Mechs-Gorgon";
import kobold from "./Mechs-Kobold";
import pegasus2 from "./Mechs-Pegasus-2";
import pegasus2Extruded from "./Mechs-Pegasus-2-Extruded";
import gorgonTurret from "./Mechs-Gorgon-Turret";
import rkfCalendula from "./Mechs-RKF-Calendula";
import MechsLycanSilent from "./Mechs-Lycan-Silent";
import MechsLycanLoud from "./Mechs-Lycan-Loud";

export default [
    Object.assign({ value: 0, label: "BALOR - COMBATANT" }, balor),
    Object.assign({ value: 1, label: "BALOR - AGGREGANT" , isPremium: true, isLegendary: true }, balorAggregant),
    Object.assign({ value: 2, label: "GOBLIN - FLEA", isPremium: true, isLegendary: true }, flea),
    Object.assign({ value: 3, label: "GOBLIN" }, goblin),
    Object.assign({ value: 4, label: "GOBLIN 2.0" }, goblin2),
    Object.assign({ value: 5, label: "GORGON" }, gorgon),
    Object.assign({ value: 6, label: "GORGON - SENTRY DRONE" }, gorgonTurret),
    Object.assign({ value: 7, label: "HYDRA" }, hydra),
    Object.assign({ value: 8, label: "HYDRA - DRONES" }, drones),
    Object.assign({ value: 9, label: "KOBOLD" }, kobold),
    Object.assign({ value: 10, label: "LICH" }, lich),
    Object.assign({ value: 11, label: "LYCAN - LOUD" }, MechsLycanLoud),
    Object.assign({ value: 12, label: "LYCAN - SILENT" }, MechsLycanSilent),
    Object.assign({ value: 13, label: "MANTICORE" }, manticore),
    Object.assign({ value: 14, label: "MANTICORE - CASTIGATED" }, castigated),
    Object.assign({ value: 15, label: "MINOTAUR" }, minotaur),
    Object.assign({ value: 16, label: "PEGASUS" }, pegasus),
    Object.assign({ value: 17, label: "PEGASUS 2.0" }, pegasus2),
    Object.assign({ value: 18, label: "¿%:?EXTR!UDE GUN" }, pegasus2Extruded),
    Object.assign({ value: 19, label: "RKF CALENDULA" }, rkfCalendula),
];
