import BaseURL from "./../../../Unit/data/images/Hosting-BaseURL";

export const latestNewsDate = "2024111501";

const newsItems = [
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/nov-15-2024-116043763",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid November Update",
                src: `${BaseURL}/news/2024-11-15.png`,
            },
        ],
        date: "2024-11-15",
        title: "Mid November Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/nov-1-2024-115065103",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of November Update",
                src: `${BaseURL}/news/2024-11-01.png`,
            },
        ],
        date: "2024-11-01",
        title: "Start of November Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/october-15-2024-114037885",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid October Update",
                src: `${BaseURL}/news/2024-10-15.png`,
            },
        ],
        date: "2024-10-15",
        title: "Mid October Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/october-1-2024-113055130",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of October Update",
                src: `${BaseURL}/news/2024-10-01.png`,
            },
        ],
        date: "2024-10-01",
        title: "Start of October Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/9-15-2024-update-112100937",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid September Update",
                src: `${BaseURL}/news/2024-09-15.png`,
            },
        ],
        date: "2024-09-15",
        title: "Mid September Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/sep-3-2024-111321952",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of September Update",
                src: `${BaseURL}/news/2024-09-03.png`,
            },
        ],
        date: "2024-09-03",
        title: "Start of September Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/aug-15-2024-110201424",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid August Update",
                src: `${BaseURL}/news/2024-08-15.png`,
            },
        ],
        date: "2024-08-15",
        title: "Mid August Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/aug-1-2024-109165295",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of August Update",
                src: `${BaseURL}/news/2024-08-01.png`,
            },
        ],
        date: "2024-08-01",
        title: "Start of August Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/7-15-2024-update-108166222",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid July Update",
                src: `${BaseURL}/news/2024-07-15.png`,
            },
        ],
        date: "2024-07-15",
        title: "Mid July Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/7-1-2024-update-107234991",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of July Update",
                src: `${BaseURL}/news/2024-07-01.png`,
            },
        ],
        date: "2024-07-01",
        title: "Start of July Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/6-15-2024-update-106285338",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid June Update",
                src: `${BaseURL}/news/2024-06-15.png`,
            },
        ],
        date: "2024-06-15",
        title: "Mid June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/6-1-2024-update-105362919",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of June Update",
                src: `${BaseURL}/news/2024-06-01.png`,
            },
        ],
        date: "2024-06-01",
        title: "Start of June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/5-15-2024-update-104294188",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid May Update",
                src: `${BaseURL}/news/2024-05-15.png`,
            },
        ],
        date: "2024-05-15",
        title: "Mid May Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/5-1-2024-update-103408718",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of May Update",
                src: `${BaseURL}/news/2024-05-01.png`,
            },
        ],
        date: "2024-05-01",
        title: "Start of May Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/4-1-2024-update-101481505",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of April Update",
                src: `${BaseURL}/news/2024-04-01.png`,
            },
        ],
        date: "2024-04-01",
        title: "Start of April Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/3-15-2024-update-100474510",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Ides of March Update",
                src: `${BaseURL}/news/2024-03-15.png`,
            },
        ],
        date: "2024-03-15",
        title: "Ides of March Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/3-2-2024-update-99525659",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of March Update",
                src: `${BaseURL}/news/2024-03-01.png`,
            },
        ],
        date: "2024-03-01",
        title: "Start of March Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/2-15-2024-update-98521218",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid February Update",
                src: `${BaseURL}/news/2024-02-15.png`,
            },
        ],
        date: "2024-02-15",
        title: "Mid February Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "Let Void Fester is a collaboration organized by Kelfecil's Tales! We bring you a pack full of stuff that you can take straight to your table (or your favorite VTT) and enjoy an epic TTRPG adventure with your friends!",
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Let Void Fester module",
                src: `${BaseURL}/news/kelfecil-let-void-fester-1.jpg`,
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Let Void Fester collaboration",
                src: `${BaseURL}/news/kelfecil-let-void-fester-2.jpg`,
            },
            {
                type: "link",
                link: "https://www.kelfecilstales.com/",
                text: "Check out Kelfecil's site here!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/let-void-fester-98088924",
                text: "Check out Retrograde's Patreon post here!",
            },
        ],
        date: "2024-02-08",
        title: "Kelfecil's Tales - Let Void Fester",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/2-1-2024-update-97636871",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of February Update",
                src: `${BaseURL}/news/2024-02-01.png`,
            },
        ],
        date: "2024-02-01",
        title: "Start of February Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/1-1-2024-update-95651573",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of January Update",
                src: `${BaseURL}/news/2024-01-01.png`,
            },
        ],
        date: "2024-01-01",
        title: "Start of January Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/12-15-2023-94715063",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid December Update",
                src: `${BaseURL}/news/2023-12-15.png`,
            },
        ],
        date: "2023-12-15",
        title: "Mid December Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/12-03-2023-93994140",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of December Update",
                src: `${BaseURL}/news/2023-12-01.png`,
            },
        ],
        date: "2023-12-01",
        title: "Start of December Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/11-15-2023-92993169",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid November Update",
                src: `${BaseURL}/news/2023-11-15.png`,
            },
        ],
        date: "2023-11-15",
        title: "Mid November Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/10-12-2023-90837906",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid October Update",
                src: `${BaseURL}/news/2023-10-12.png`,
            },
        ],
        date: "2023-10-12",
        title: "Mid October Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/9-17-2023-update-89402589",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid September Update",
                src: `${BaseURL}/news/2023-09-17.png`,
            },
        ],
        date: "2023-09-17",
        title: "Mid September Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/08-01-2023-86950574",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of August Update",
                src: `${BaseURL}/news/2023-08-01.png`,
            },
        ],
        date: "2023-08-01",
        title: "Start of August Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/7-01-2023-update-85469356",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of July Update",
                src: `${BaseURL}/news/2023-07-01.png`,
            },
        ],
        date: "2023-07-01",
        title: "Start of July Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/6-1-2023-update-83866946",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of June Update",
                src: `${BaseURL}/news/2023-06-01.png`,
            },
        ],
        date: "2023-06-01",
        title: "Start of June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/05-01-2023-82276889",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of May Update",
                src: `${BaseURL}/news/2023-05-01.png`,
            },
        ],
        date: "2023-05-01",
        title: "Start of May Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "Scarlet Blitz is a collaboration organized by Kelfecil's Tales! We bring you a pack full of stuff that you can take straight to your table (or your favorite VTT) and enjoy an epic TTRPG adventure with your friends!",
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Scarlet Blitz module",
                src: `${BaseURL}/news/kelfecil-scarlet-blitz-1.jpg`,
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Scarlet Blitz collaboration",
                src: `${BaseURL}/news/kelfecil-scarlet-blitz-2.jpg`,
            },
            {
                type: "link",
                link: "https://www.kelfecilstales.com/",
                text: "Check out Kelfecil's site here!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/5e-module-blitz-80910508",
                text: "Check out Retrograde's Patreon post here!",
            },
        ],
        date: "2023-03-28",
        title: "Kelfecil's Tales - Scarlet Blitz",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/4-1-2023-update-80910175",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of April Update",
                src: `${BaseURL}/news/2023-04-01.png`,
            },
        ],
        date: "2023-04-01",
        title: "Start of April Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/3-1-2023-update-79383991",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of March Update",
                src: `${BaseURL}/news/2023-03-01.png`,
            },
        ],
        date: "2023-03-01",
        title: "Start of March Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/2-01-2023-update-78070367",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of February Update",
                src: `${BaseURL}/news/2023-02-01.png`,
            },
        ],
        date: "2023-02-01",
        title: "Start of February Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/01-15-2023-77292571",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid January Update",
                src: `${BaseURL}/news/2023-01-15.png`,
            },
        ],
        date: "2023-01-15",
        title: "Mid January Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/12-20-2022-76133385",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid December Update",
                src: `${BaseURL}/news/2022-12-15.png`,
            },
        ],
        date: "2022-12-20",
        title: "Mid December Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/update-12-4-2022-75485799",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of December Update",
                src: `${BaseURL}/news/2022-12-01.png`,
            },
        ],
        date: "2022-12-01",
        title: "Start of December Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "Whisper of Flames is a collaboration organized by Kelfecil's Tales! We bring you a pack full of stuff that you can take straight to your table (or your favorite VTT) and enjoy an epic TTRPG adventure with your friends!",
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Whisper of Flames module",
                src: `${BaseURL}/news/kelfecil-whisper-of-flames-1.jpg`,
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Whisper of Flames collaboration",
                src: `${BaseURL}/news/kelfecil-whisper-of-flames-2.jpg`,
            },
            {
                type: "link",
                link: "https://www.kelfecilstales.com/",
                text: "Check out Kelfecil's site here!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/whisper-of-5e-73997775",
                text: "Check out Retrograde's Patreon post here!",
            },
        ],
        date: "2022-11-01",
        title: "Kelfecil's Tales - Whisper of Flames",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/11-1-2022-update-73997556",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of November Update",
                src: `${BaseURL}/news/2022-11-01.png`,
            },
        ],
        date: "2022-11-01",
        title: "Start of November Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/update-10-1-2022-72815396",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of October Update",
                src: `${BaseURL}/news/2022-10-01.png`,
            },
        ],
        date: "2022-10-01",
        title: "Start of October Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "The link is hard to click for the MO&S RASPUTIN, so check out the source here!",
            },
            {
                type: "link",
                link: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
                text: "The Field Guide to Liminal Space",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/9-15-2022-update-72136192",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid September Update",
                src: `${BaseURL}/news/2022-09-15.png`,
            },
        ],
        date: "2022-09-15",
        title: "Mid September Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/9-1-2022-update-71448423",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of September Update",
                src: `${BaseURL}/news/2022-09-01.png`,
            },
        ],
        date: "2022-09-01",
        title: "Start of September Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "Crimson Harvest is a collaboration organized by Kelfecil's Tales! We bring you a pack full of stuff that you can take straight to your table (or your favorite VTT) and enjoy an epic TTRPG adventure with your friends!",
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Crimson Harvest module",
                src: `${BaseURL}/news/kelfecil-crimson-harvest-1.jpg`,
            },
            {
                type: "image",
                alt: "Kelfecil's Tales - Crimson Harvest collaboration",
                src: `${BaseURL}/news/kelfecil-crimson-harvest-2.jpg`,
            },
            {
                type: "link",
                link: "https://www.kelfecilstales.com/",
                text: "Check out Kelfecil's site here!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/crimson-harvest-70597753",
                text: "Check out Retrograde's Patreon post here!",
            },
        ],
        date: "2022-08-16",
        title: "Kelfecil's Tales - Crimson Harvest",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/8-15-2022-update-70529732",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid August Update",
                src: `${BaseURL}/news/2022-08-15.png`,
            },
        ],
        date: "2022-08-15",
        title: "Mid August Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/8-1-2022-update-70141498",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of August Update",
                src: `${BaseURL}/news/2022-08-01.png`,
            },
        ],
        date: "2022-08-01",
        title: "Start of August Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "The link is hard to click for the MOI SOLOMON DRONES, so check out the source here!",
            },
            {
                type: "link",
                link: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
                text: "The Field Guide to Liminal Space",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/7-1-20222-update-68596101",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of July Update",
                src: `${BaseURL}/news/2022-07-01.png`,
            },
        ],
        date: "2022-07-01",
        title: "Start of July Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "The Custom Mech Galiban created by PinkHyena has arrived! The +2 Patroen Tiers help support creators like this!",
            },
            {
                type: "text",
                text: "As always, a fresh crop of Chop Shop content has also been added!",
            },
        ],
        date: "2022-06-15",
        title: "Mid June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/6-1-2022-update-67167953",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of June Update",
                src: `${BaseURL}/news/2022-06-01.png`,
            },
        ],
        date: "2022-06-01",
        title: "Start of June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/5-15-2022-update-66442906",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid May Update",
                src: `${BaseURL}/news/2022-05-15.png`,
            },
        ],
        date: "2022-05-15",
        title: "Mid May Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/5-1-2022-update-65759511",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of May Update",
                src: `${BaseURL}/news/2022-05-01.png`,
            },
        ],
        date: "2022-05-01",
        title: "Start of May Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/4-15-2022-update-65165333",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid April Update",
                src: `${BaseURL}/news/2022-04-15.png`,
            },
        ],
        date: "2022-04-15",
        title: "Mid April Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://qm-vox.tumblr.com/post/670936125146382336/field-guide-liminal-space",
                text: "The Field Guide to Liminal Space",
            },
            {
                type: "text",
                text: "The link is hard to click for the MO&S FRANKENSTEIN, so check out the source here!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/4-15-2022-update-64575867",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of April Update",
                src: `${BaseURL}/news/2022-04-01.png`,
            },
        ],
        date: "2022-04-01",
        title: "Start of April Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/3-15-2022-update-63820705",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid March Update",
                src: `${BaseURL}/news/2022-03-15.png`,
            },
        ],
        date: "2022-03-15",
        title: "Mid March Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/3-1-2022-update-63161261",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of March Update",
                src: `${BaseURL}/news/2022-03-01.png`,
            },
        ],
        date: "2022-03-01",
        title: "Start of March Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/2-15-2022-update-62587877",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid February Update",
                src: `${BaseURL}/news/2022-02-15.png`,
            },
        ],
        date: "2022-02-15",
        title: "Mid February Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/2-1-2022-update-61887903",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of February Update",
                src: `${BaseURL}/news/2022-02-01.png`,
            },
        ],
        date: "2022-02-01",
        title: "Start of February Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/1-15-2022-update-61151007",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid January Update",
                src: `${BaseURL}/news/2022-01-15.png`,
            },
        ],
        date: "2022-01-15",
        title: "Mid January Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "Some lovely Chop Shop and a Tavern dweller to start off the New Year!",
            },
            {
                type: "text",
                text: "We will return with more content in the Mid January update!",
            },
        ],
        date: "2022-01-01",
        title: "Start of January Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/12-15-2021-59941037",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid December Update",
                src: `${BaseURL}/news/2021-12-15.png`,
            },
        ],
        date: "2021-12-15",
        title: "Mid December Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/12-1-2021-update-59374762",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of December Update",
                src: `${BaseURL}/news/2021-12-01.png`,
            },
        ],
        date: "2021-12-01",
        title: "Start of December Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/11-15-2021-58726295",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid November Update",
                src: `${BaseURL}/news/2021-11-15.png`,
            },
        ],
        date: "2021-11-15",
        title: "Mid November Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/11-1-2021-update-58149777",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of November Update",
                src: `${BaseURL}/news/2021-11-01.png`,
            },
        ],
        date: "2021-11-01",
        title: "Start of November Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "We're participating in a little collaboration with Kelfecil's Tales - We're proud to be part of this edition of their Sunday Tales bundle for The Defense of Sirmia adventure module!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/releases-for-2-57503157",
                text: "Check out the Kelfecil's Patreon post here!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/sunday-tales-of-57509780",
                text: "Check out the Retrograde's Patreon post here!",
            },
        ],
        date: "2021-10-18",
        title: "Sunday Tales - The Defense of Sirmia",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/10-15-2021-57436681",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid October Update",
                src: `${BaseURL}/news/2021-10-15.png`,
            },
        ],
        date: "2021-10-15",
        title: "Mid October Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/10-1-2021-update-56754130",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of October Update",
                src: `${BaseURL}/news/2021-10-01.png`,
            },
        ],
        date: "2021-10-01",
        title: "Start of October Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/update-9-15-2021-56192497",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid September Update",
                src: `${BaseURL}/news/2021-09-15.png`,
            },
        ],
        date: "2021-9-15",
        title: "Mid September Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/9-1-2021-update-55794031",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of September Update",
                src: `${BaseURL}/news/2021-09-01.png`,
            },
        ],
        date: "2021-9-01",
        title: "Start of September Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/8-15-2021-update-54854209",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid August Update",
                src: `${BaseURL}/news/2021-08-15.png`,
            },
        ],
        date: "2021-8-15",
        title: "Mid August Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/8-1-2021-update-54371093",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of August Update",
                src: `${BaseURL}/news/2021-08-01.png`,
            },
        ],
        date: "2021-8-01",
        title: "Start of August Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/7-15-2021-update-53716674",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid July Update",
                src: `${BaseURL}/news/2021-07-15.png`,
            },
        ],
        date: "2021-7-15",
        title: "Mid July Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/7-1-2021-update-53109408",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of July Update",
                src: `${BaseURL}/news/2021-07-01.png`,
            },
        ],
        date: "2021-7-01",
        title: "Start of July Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/6-15-2021-update-52797468",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid June Update",
                src: `${BaseURL}/news/2021-06-15.png`,
            },
        ],
        date: "2021-6-15",
        title: "Mid June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/6-1-2021-update-51861461",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of June Update",
                src: `${BaseURL}/news/2021-06-01.png`,
            },
        ],
        date: "2021-6-01",
        title: "Start of June Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/5-15-2021-update-51278920",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Mid May Update",
                src: `${BaseURL}/news/2021-05-15.png`,
            },
        ],
        date: "2021-5-15",
        title: "Mid May Update",
    },
    {
        contents: [
            {
                type: "link",
                link: "https://www.patreon.com/posts/5-1-2021-update-50713541",
                text: "Check out the Patreon post here!",
            },
            {
                type: "image",
                alt: "Start of May Update",
                src: `${BaseURL}/news/2021-05-01.png`,
            },
        ],
        date: "2021-5-01",
        title: "Start of May Update",
    },
    {
        contents: [
            {
                type: "text",
                text: "New content on the scene! Custom Fantasy creator with our first offering, The Tank. As well as some other Mech content!",
            },
            {
                type: "link",
                link: "https://www.patreon.com/posts/1-31-2021-update-46895896",
                text: "Check out the update details here!",
            },
            {
                type: "text",
                text: "We also have a Discord channel where you can get previews of our current works in progress, make requests, or just chat about monsters and mechs.",
            },
            {
                type: "link",
                link: "https://discord.gg/JUF47G5",
                text: "Join our Discord here!",
            },
        ],
        date: "2021-1-31",
        title: "Custom Creator",
    },
    {
        contents: [
            {
                type: "text",
                text: "New content on the scene! Guards, Goblins, Dice Monsters, and a Custom Mech Scorpi-O!",
            },
            {
                type: "text",
                text: "As you might have already heard, we have added a little music to the site, you can control it on the bottom right of the screen. We commissioned the tracks from tfx.",
            },
            {
                type: "text",
                text: "You can visit them by clicking the music title in the controls!",
            },
            {
                type: "link",
                link: "http://tfx.seawavescollective.net/",
                text: "Check out tfx's work here.",
            },
            {
                type: "text",
                text: "We have also added a new Tags system, search for what you are looking for an have it all pop up in one easy spot! The current tag list includes weapons, ranged, magic, archetypes, cloths / armor, and colors!",
            },
            {
                type: "text",
                text: "Two more token packs have been added to our Roll20 listing, Natural and Evil packs!",
            },
            {
                type: "link",
                link: "https://marketplace.roll20.net/browse/publisher/463/retrograde-minis",
                text: "Check out our Roll20 tokens here!",
            },
            {
                type: "text",
                text: "We also have a Discord channel where you can get previews of our current works in progress, make requests, or just chat about monsters and mechs.",
            },
            {
                type: "link",
                link: "https://discord.gg/JUF47G5",
                text: "Join our Discord here!",
            },
        ],
        date: "2020-5-15",
        title: "Beat Cops on Duty",
    },
    {
        contents: [
            {
                type: "text",
                text: "Until now, new content on Retrograde has been uploaded and released on a rolling basis, but starting this March we'll be switching to a bimonthly update schedule. Content updates will be released in larger batches on the 1st and 15th of each month.",
            },
            {
                type: "text",
                text: "There's two main reasons - first, this will give us a little more time to iron out any bugs - each of the new Lancer units require some degree of customization and fine-tuning to get all the parts displaying correctly, and secondly this will also encourage our patrons to renew their membership at the beginning of each month to get the new goods.",
            },
            {
                type: "text",
                text: "We're still producing new content at the same rate (as fast as we can!) The only change will be the release schedule, so each update will feature multiple new units instead of one by one every couple of days.",
            },
            {
                type: "link",
                link: "https://discord.gg/JUF47G5",
                text: "Join our Discord here!",
            },
            {
                type: "link",
                link: "https://marketplace.roll20.net/browse/publisher/463/retrograde-minis",
                text: "Check out our Roll20 tokens here!",
            },
        ],
        date: "2020-2-25",
        title: "Update Schedule Announcement",
    },
    {
        contents: [
            {
                type: "text",
                text: "We have continued to add more Mechs! Check them out under the IPS-N and Horus categories.",
            },
            {
                type: "text",
                text: "Several token sets for sale on Roll20! They include Undead, Humans, Orcs, and Goblins. With more to come!",
            },
            {
                type: "link",
                link: "https://marketplace.roll20.net/browse/publisher/463/retrograde-minis",
                text: "Check out the Roll20 tokens here!",
            },
            {
                type: "text",
                text: "Lastly, we have also setup a Discord server for quick and easy feedback, discussion, tips, and the latest updates!",
            },
            {
                type: "link",
                link: "https://discord.gg/JUF47G5",
                text: "Join our Discord here!",
            },
        ],
        date: "2020-2-22",
        title: "More Mechs Mobilizing",
    },
    {
        contents: [
            {
                type: "text",
                text: "CONSTRUCTION COMPLETED",
            },
            {
                type: "text",
                text: "We now have a selection of Mech parts you can use to mix and match.",
            },
            {
                type: "text",
                text: "Select your parts, Apply some paint, Fabricate your Mech to the field, and Print them out!",
            },
            {
                type: "text",
                text: "You can click the RetroGrade image to switch back to the regular theme.",
            },
        ],
        date: "2020-1-17",
        title: "Facility Online",
    },
    {
        contents: [
            {
                type: "text",
                text: "The full array of Christmas units have arrived!",
            },
            {
                type: "text",
                text: "Don't look a gift, regular or horse, in the mouth this Christmas!",
            },
            {
                type: "text",
                text: "Enjoy these seasonal units for free until the New Year rolls in!",
            },
            {
                type: "text",
                text: "You can click the RetroGrade image to switch back to the regular theme.",
            },
        ],
        date: "2019-11-30",
        title: "Dreaming of a Red Christmas",
    },
    {
        contents: [
            {
                type: "text",
                text: "It is cold outside and here on RetroGrade!",
            },
            {
                type: "text",
                text: "The Christmas category has arrived with the first set of units being the green and red soldiers in the Salvation Horde.",
            },
            {
                type: "text",
                text: "More units will be arriving to fill out the festive roster, so check back for updates!",
            },
            {
                type: "text",
                text: "You can click the RetroGrade image to switch back to the regular theme.",
            },
        ],
        date: "2019-11-17",
        title: "Yuletides of War",
    },
    {
        contents: [
            {
                type: "text",
                text: "Arboreal Autumn continues!",
            },
            {
                type: "text",
                text: "The latest addition to our menagerie is the Plants subcategory - including cactusfolk, venus honeytrap, vineblights, and a familiar tendril, with more to come soon!",
            },
        ],
        date: "2019-10-20",
        title: "Thistle be the day that I die",
    },
    {
        contents: [
            {
                type: "text",
                text: "Ever wonder just what makes people so fanatical about pumpkin spiced foods?",
            },
            {
                type: "text",
                text: "We think we've found the 'Source'.",
            },
            {
                type: "text",
                text: "New Pumpkinfolk units are available for everyone and a little bit of that autumn feeling has arrived at RetroGrade Minis!",
            },
            {
                type: "text",
                text: "You can click the RetroGrade image to switch back to the regular theme.",
            },
        ],
        date: "2019-09-24",
        title: "The Dark Secret Behind Pumpkin Spice Lattes",
    },
    {
        contents: [
            {
                type: "text",
                text: "We have been hard at work shuffling and tweaking to get this site and all of its pixels to fit onto tablet and phone screens!",
            },
            {
                type: "text",
                text: "We hope it works swell for everyone! However if it does not, or you have any issues or suggestions, feel free to contact us at retrogrademinis@gmail.com",
            },
            {
                type: "text",
                text: "We also have a new unit category available, the Cultists!",
            },
        ],
        date: "2019-09-05",
        title: "The Cult of Mo'bile has Risen!",
    },
    {
        contents: [
            {
                type: "text",
                text: "Pardon our dust, we're still getting things sorted!",
            },
            {
                type: "text",
                text: "If you've somehow stumbled onto this site, well, enjoy!",
            },
        ],
        date: "2019-08-??",
        title: "Under Construction",
    },
];

export default newsItems;
