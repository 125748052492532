import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/Starforce`;

// BODY
const bodyChassisImage = `${imagePath}/STARFORCE_MAIN.png`;

// HEADS
const headStarforceImage = `${imagePath}/heads/STARFORCE_HEAD_DEFAULT.png`;
const headDRAGOONImage = `${imagePath}/heads/STARFORCE_HEAD_DRAGOON.png`;
const headKAZUImage = `${imagePath}/heads/STARFORCE_HEAD_KAZU.png`;
const headMECHA1Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA1.png`;
const headMECHA2Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA2.png`;
const headMECHA3Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA3.png`;
const headMECHA4Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA4.png`;
const headMECHA5Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA5.png`;
const headMECHA6Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA6.png`;
const headMECHA7Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA7.png`;
const headMECHA8Image = `${imagePath}/heads/STARFORCE_HEAD_MECHA8.png`;
const headNATURALLAWImage = `${imagePath}/heads/STARFORCE_HEAD_NATURALLAW.png`;
const headSCOPEHOUNDImage = `${imagePath}/heads/STARFORCE_HEAD_SCOPEHOUND.png`;
const headSCOPEHOUNDDAPPERImage = `${imagePath}/heads/STARFORCE_HEAD_SCOPEHOUNDDAPPER.png`;

// WEAPONS
// L
const weaponLFISTImage = `${imagePath}/weapon/STARFORCE_WEAPON_FIST_L.png`;
const weaponLKNIFEImage = `${imagePath}/weapon/STARFORCE_WEAPON_KNIFE_L.png`;
const weaponLPIZZAImage = `${imagePath}/weapon/STARFORCE_WEAPON_PIZZA_L.png`;
const weaponLSTARCANNONImage = `${imagePath}/weapon/STARFORCE_WEAPON_STARCANNON_L.png`;

// R
const weaponRKNIFEImage = `${imagePath}/weapon/STARFORCE_WEAPON_KNIFE_R.png`;

// GLOWS
const glowStarcannonImage = `${imagePath}/glow/STARFORCE_ACCESSORY_STARCANNON_GLOW.png`;

// ACCESSORIES
const accessoryWaistcloakImage = `${imagePath}/accessory/STARFORCE_ACCESSORY_WAISTCLOAK.png`;

const previewImage = `${imagePath}/preview.png`;

const coreImage = `${imagePath}/STARFORCE_CORE-gummies139.png`;

const bipedLegsShadow = {
    size: shadowTypes.MEDIUM,
    bottom: "105px",
    right: "94px",
};

const originalArtCredit = {
    prefixText: "Created by ",
    title: "Gummies139",
    url: "https://discord.gg/JUF47G5",
};

export default {
    isLarge: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit,
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "3",
        "accessory1Value": "0",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "STARFORCE",
            src: headStarforceImage,
        },
        {
            value: 1,
            label: "DRAGOON",
            src: headDRAGOONImage,
        },
        {
            value: 2,
            label: "KAZU",
            src: headKAZUImage,
        },
        {
            value: 3,
            label: "MECHA 1",
            src: headMECHA1Image,
        },
        {
            value: 4,
            label: "MECHA 2",
            src: headMECHA2Image,
        },
        {
            value: 5,
            label: "MECHA 3",
            src: headMECHA3Image,
        },
        {
            value: 6,
            label: "MECHA 4",
            src: headMECHA4Image,
        },
        {
            value: 7,
            label: "MECHA 5",
            src: headMECHA5Image,
        },
        {
            value: 8,
            label: "MECHA 6",
            src: headMECHA6Image,
        },
        {
            value: 9,
            label: "MECHA 7",
            src: headMECHA7Image,
        },
        {
            value: 10,
            label: "MECHA 8",
            src: headMECHA8Image,
        },
        {
            value: 11,
            label: "NATURAL LAW",
            src: headNATURALLAWImage,
        },
        {
            value: 12,
            label: "SCOPEHOUND",
            src: headSCOPEHOUNDImage,
        },
        {
            value: 13,
            label: "SCOPEHOUND - DAPPER",
            src: headSCOPEHOUNDDAPPERImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "STARFORCE",
            src: bodyChassisImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "KNIFE",
                src: weaponRKNIFEImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "FIST",
            src: weaponLFISTImage,
        },
        {
            value: 1,
            label: "KNIFE",
            src: weaponLKNIFEImage,
        },
        {
            value: 2,
            label: "PIZZA",
            src: weaponLPIZZAImage,
        },
        {
            value: 3,
            label: "STARCANNON",
            src: weaponLSTARCANNONImage,
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "WAISTCLOAK",
            src: accessoryWaistcloakImage,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "STARCANNON",
            foremostSrc: glowStarcannonImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON L",
            value: "weapon",
        },
        {
            label: "WEAPON R",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY",
            value: "rearMount",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "ACCESSORY",
        "weapon": "WEAPON L",
        "secondaryWeapon": "WEAPON R",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
