import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Horus/Lycan-Silent`;

// BODY
const bodyChassis1Image = `${imagePath}/Chassis 1.png`;
const bodyChassis2Image = `${imagePath}/Chassis 2.png`;

// HEADS
const headLycanImage = `${imagePath}/heads/Head - Lycan.png`;
const headLycan2Image = `${imagePath}/heads/Head - Lycan 2.png`;
const head8BallImage = `${imagePath}/heads/Head - 8-Ball.png`;
const headBlackspotImage = `${imagePath}/heads/Head - Blackspot.png`;
const headCalibanImage = `${imagePath}/heads/Head - Caliban.png`;
const headCheddahImage = `${imagePath}/heads/Head - Cheddah.png`;
const headChimeraImage = `${imagePath}/heads/Head - Chimera.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headDomImage = `${imagePath}/heads/Head - Dom.png`;
const headDungamImage = `${imagePath}/heads/Head - Dungam.png`;
const headEnkiduImage = `${imagePath}/heads/Head - Enkidu.png`;
const headHornedImage = `${imagePath}/heads/Head - Horned.png`;
const headHornedHairImage = `${imagePath}/heads/Head - Horned Hair.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headLaborerImage = `${imagePath}/heads/Head - Laborer.png`;
const headMagImage = `${imagePath}/heads/Head - Mag.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headMecha1Image = `${imagePath}/heads/Head - Mecha 1.png`;
const headMecha2Image = `${imagePath}/heads/Head - Mecha 2.png`;
const headMecha3Image = `${imagePath}/heads/Head - Mecha 3.png`;
const headMecha4Image = `${imagePath}/heads/Head - Mecha 4.png`;
const headMecha5Image = `${imagePath}/heads/Head - Mecha 5.png`;
const headMecha6Image = `${imagePath}/heads/Head - Mecha 6.png`;
const headMecha7Image = `${imagePath}/heads/Head - Mecha 7.png`;
const headMecha8Image = `${imagePath}/heads/Head - Mecha 8.png`;
const headNaturalLawImage = `${imagePath}/heads/Head - Natural Law.png`;
const headNaturalLawHairImage = `${imagePath}/heads/Head - Natural Law Hair.png`;
const headNemesisImage = `${imagePath}/heads/Head - Nemesis.png`;
const headRainTransformedImage = `${imagePath}/heads/Head - Rain Transformed.png`;
const headRainTransformedHairImage = `${imagePath}/heads/Head - Rain Transformed Hair.png`;
const headSkullImage = `${imagePath}/heads/Head - Skull.png`;
const headVanquisherImage = `${imagePath}/heads/Head - Vanquisher.png`;
const headViceroyImage = `${imagePath}/heads/Head - Viceroy.png`;
const headVisionaryImage = `${imagePath}/heads/Head - Visionary.png`;

// WEAPONS
// L
const weaponLAxeImage = `${imagePath}/weapons/left/Left Arm - Axe.png`;
const weaponLHandImage = `${imagePath}/weapons/left/Left Arm - Hand.png`;
const weaponLHeavyAxeImage = `${imagePath}/weapons/left/Left Arm - Heavy Axe.png`;
const accessoryHeavyAxeImage = `${imagePath}/accessory/Accessory Under - Heavy Axe.png`;
const weaponLHMGImage = `${imagePath}/weapons/left/Left Arm - HMG.png`;
const weaponLShotgunImage = `${imagePath}/weapons/left/Left Arm - Shotgun.png`;
const weaponLSMGImage = `${imagePath}/weapons/left/Left Arm - SMG.png`;

// R
const weaponRAxeImage = `${imagePath}/weapons/right/Right Arm - Axe.png`;
const weaponRBlade1Image = `${imagePath}/weapons/right/Right Arm - Blade 1.png`;
const weaponRBlade2Image = `${imagePath}/weapons/right/Right Arm - Blade 2.png`;
const weaponRHandImage = `${imagePath}/weapons/right/Right Arm - Hand.png`;
const weaponRShieldImage = `${imagePath}/weapons/right/Right Arm - Shield.png`;
const weaponRSMGImage = `${imagePath}/weapons/right/Right Arm - SMG.png`;

// ACCESSORIES
const accessoryStrapsImage = `${imagePath}/accessory/Accessory - Straps.png`;
const accessoryStrapsUnderImage = `${imagePath}/accessory/Accessory Under - Straps.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image =  `${imagePath}/corebook_lycan_toast.png`;
const core2Image =  `${imagePath}/Lycan_by_gummies139.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "67px",
    right: "47px",
};

export default {
    isMegadeusPlus: true,
    rearMountsAreChassis: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "snipertoaster",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Gummies139",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "NONE",
        "weaponValue": "0",
        "secondaryWeaponValue": "5",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "LYCAN",
            src: headLycanImage,
        },
        {
            value: 1,
            label: "LYCAN 2",
            src: headLycan2Image,
        },
        {
            value: 2,
            label: "8 BALL",
            src: head8BallImage,
        },
        {
            value: 3,
            label: "BLACKSPOT",
            src: headBlackspotImage,
        },
        {
            value: 4,
            label: "CALIBAN",
            src: headCalibanImage,
        },
        {
            value: 5,
            label: "CHEDDAH",
            src: headCheddahImage,
        },
        {
            value: 6,
            label: "CHIMERA",
            src: headChimeraImage,
        },
        {
            value: 7,
            label: "CYCLOPS",
            src: headCyclopsImage,
        },
        {
            value: 8,
            label: "DOM",
            src: headDomImage,
        },
        {
            value: 9,
            label: "DUNGAM",
            src: headDungamImage,
        },
        {
            value: 10,
            label: "ENKIDU",
            src: headEnkiduImage,
        },
        {
            value: 11,
            label: "HORNED",
            src: headHornedImage,
        },
        {
            value: 12,
            label: "HORNED HAIR",
            src: headHornedHairImage,
        },
        {
            value: 13,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 14,
            label: "LABORER",
            src: headLaborerImage,
        },
        {
            value: 15,
            label: "MAG",
            src: headMagImage,
        },
        {
            value: 16,
            label: "MALICE",
            src: headMaliceImage,
        },
        {
            value: 17,
            label: "MECHA 1",
            src: headMecha1Image,
        },
        {
            value: 18,
            label: "MECHA 2",
            src: headMecha2Image,
        },
        {
            value: 19,
            label: "MECHA 3",
            src: headMecha3Image,
        },
        {
            value: 20,
            label: "MECHA 4",
            src: headMecha4Image,
        },
        {
            value: 21,
            label: "MECHA 5",
            src: headMecha5Image,
        },
        {
            value: 22,
            label: "MECHA 6",
            src: headMecha6Image,
        },
        {
            value: 23,
            label: "MECHA 7",
            src: headMecha7Image,
        },
        {
            value: 24,
            label: "MECHA 8",
            src: headMecha8Image,
        },
        {
            value: 25,
            label: "NATURAL LAW",
            src: headNaturalLawImage,
        },
        {
            value: 26,
            label: "NATURAL LAW HAIR",
            src: headNaturalLawHairImage,
        },
        {
            value: 27,
            label: "NEMESIS",
            src: headNemesisImage,
        },
        {
            value: 28,
            label: "RAIN TRANSFORMED",
            src: headRainTransformedImage,
        },
        {
            value: 29,
            label: "RAIN TRANSFORMED HAIR",
            src: headRainTransformedHairImage,
        },
        {
            value: 30,
            label: "SKULL",
            src: headSkullImage,
        },
        {
            value: 31,
            label: "VANQUISHER",
            src: headVanquisherImage,
        },
        {
            value: 32,
            label: "VICEROY",
            src: headViceroyImage,
        },
        {
            value: 33,
            label: "VISIONARY",
            src: headVisionaryImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "SILENT",
            src: bodyChassis1Image,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "SILENT 2",
            src: bodyChassis2Image,
            shadowDetails: bipedLegsShadow,
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "AXE",
                src: weaponLAxeImage,
            },
            {
                value: 1,
                label: "HAND",
                src: weaponLHandImage,
            },
            {
                value: 2,
                label: "HEAVY AXE",
                src: weaponLHeavyAxeImage,
                backSrc: accessoryHeavyAxeImage,
            },
            {
                value: 3,
                label: "HMG",
                src: weaponLHMGImage,
            },
            {
                value: 4,
                label: "SHOTGUN",
                src: weaponLShotgunImage,
            },
            {
                value: 5,
                label: "SMG",
                src: weaponLSMGImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AXE",
            src: weaponRAxeImage,
        },
        {
            value: 1,
            label: "BLADE 1",
            src: weaponRBlade1Image,
        },
        {
            value: 2,
            label: "BLADE 2",
            src: weaponRBlade2Image,
        },
        {
            value: 3,
            label: "HAND",
            src: weaponRHandImage,
        },
        {
            value: 4,
            label: "SHIELD",
            src: weaponRShieldImage,
        },
        {
            value: 5,
            label: "SMG",
            src: weaponRSMGImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "STRAPS",
            src: accessoryStrapsImage,
            backSrc: accessoryStrapsUnderImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "WEAPON L",
            value: "weapon",
        },
        {
            label: "WEAPON R",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "ACCESSORY 3",
            value: "accessory3",
        },
        {
            label: "ACCESSORY 4",
            value: "accessory4",
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "WEAPON L",
        "secondaryWeapon": "WEAPON R",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "ACCESSORY 3",
        "accessory4": "ACCESSORY 4",
    },
};
