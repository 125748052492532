/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { createRef, useContext, useState } from "react";
import JSZip from "jszip";
import { saveAs } from 'file-saver';

import {
    InterfaceButton,
    InterfaceThirdButton,
} from "./MechCategoryStyles";
import { drawZipImages } from "./../../ExportImages";
import ExportWrapper from "../../components/ExportWrapper";

import { UserAuthContext } from "./../../../../User/UserAuthContextWrapper";
import { AnalyticsContext } from "./../../../../Analytics/AnalyticsContextWrapper";
import {
    BrandedMechPrintAnalytics,
    NPCMechPrintAnalytics,
    CustomMechPrintAnalytics,
    CustomPlusMechPrintAnalytics,
    safeName,
 } from "./../../../Print/PrintAnalytics";

import {
    brandList as mechNPCBrandList,
    npcLayerOrder,
} from "../../../LayerData/MechNPCLayerOrder";
import {
    brandList as mechBrandedBrandList,
    brandedLayerOrder,
} from "../../../LayerData/MechBrandedLayerOrder";
import { createExportLayerOptions } from "../../../LayerData/CreateLayerFunctions";

import Mechs from "./../../../data/Mechs-GMS";
import MechsCustomPlus from "./../../../data/Mechs-622";

import { premiumTiersList } from "../../PremiumPopup";

const brandList = Object.assign({},
    mechNPCBrandList,
    mechBrandedBrandList,
);

const canvasRef = createRef();

function ExportMechPartsButton({ displayTheme, unit, name, mechType, setShowPremiumPopupFunc, disabled = false, isButtonThird = false }) {
    const { user } = useContext(UserAuthContext);
    const ReactGA = useContext(AnalyticsContext);

    const isLegendaryUser = user && user.isLegendaryUser;

    const [isLoading, setIsLoading] = useState(false);

    let imageSize = 250;

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isLarge) {
        imageSize = 200;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeus) {
        imageSize = 300;
    }

    if (
        mechType === "CUSTOM-PLUS"
        || (mechType !== "CUSTOM" && brandList[unit.brandValue][unit.frameValue].isMegadeusPlus)
    ) {
        imageSize = 350;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusPlusPlus) {
        imageSize = 400;
    }

    if ((mechType !== "CUSTOM" && mechType !== "CUSTOM-PLUS") && brandList[unit.brandValue][unit.frameValue].isMegadeusOmega) {
        imageSize = 450;
    }

    async function printCustomMech(unit) {
        let imageLayersArray = [];

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].backSrc,
                filter: unit.accessory1TintValue,
                name: "accessory-1-back.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].backSrc,
                filter: unit.accessory2TintValue,
                name: "accessory-2-back.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].backSrc,
                filter: unit.accessory3TintValue,
                name: "accessory-3-back.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].backSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].backSrc,
                filter: unit.accessory4TintValue,
                name: "accessory-4-back.png",
            });
        }

        if (unit.weaponValue !== "NONE" && !!Mechs.weapon[unit.stanceValue][unit.weaponValue].backSrc) {
            imageLayersArray.push({
                src: Mechs.weapon[unit.stanceValue][unit.weaponValue].backSrc,
                filter: unit.weaponTintValue,
                name: "weapon-back.png",
            });
        }

        if (unit.secondaryWeaponValue !== "NONE" && !!Mechs.secondaryWeapon[unit.secondaryWeaponValue].backSrc) {
            imageLayersArray.push({
                src: Mechs.secondaryWeapon[unit.secondaryWeaponValue].backSrc,
                filter: unit.secondaryWeaponTintValue,
                name: "secondary-weapon-back.png",
            });
        }

        if (unit.rearMountValue !== "NONE" && !!Mechs.rearMount[unit.rearMountValue].backSrc) {
            imageLayersArray.push({
                src: Mechs.rearMount[unit.rearMountValue].backSrc,
                filter: unit.rearMountTintValue,
                name: "rear-mount-back.png",
            });
        }

        if (unit.rearMountValue !== "NONE" && !!Mechs.rearMount[unit.rearMountValue].src) {
            imageLayersArray.push({
                src: Mechs.rearMount[unit.rearMountValue].src,
                filter: unit.rearMountTintValue,
                name: "rear-mount-mid.png",
            });
        }

        if (unit.legsValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.legs[unit.legsValue].src,
                filter: unit.legsTintValue,
                name: "legs.png",
            });
        }

        if (unit.armLeftValue !== "NONE" && !!Mechs.arms[unit.armLeftValue].src[unit.stanceValue].skeleton) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armLeftValue].src[unit.stanceValue].skeleton,
                filter: unit.armLeftTintValue,
                name: "left-arm-skeleton.png",
            });
        }

        if (unit.armLeftValue !== "NONE" && (Mechs.arms[unit.armLeftValue].isMounted || Mechs.arms[unit.armLeftValue].isLeftArmBackRow)) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armLeftValue].src[unit.stanceValue].left,
                filter: unit.armLeftTintValue,
                name: "left-arm-back.png",
            });
        }

        if (unit.chassisValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.chassis[unit.chassisValue].src,
                filter: unit.chassisTintValue,
                name: "chassis.png",
            });
        }

        if (unit.rearMountValue !== "NONE" && !!Mechs.rearMount[unit.rearMountValue].frontSrc) {
            imageLayersArray.push({
                src: Mechs.rearMount[unit.rearMountValue].frontSrc,
                filter: unit.rearMountTintValue,
                name: "rear-mount-front.png",
            });
        }

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory1Value].altSrc :
                Mechs.accessory[unit.accessory1Value].src,
                filter: unit.accessory1TintValue,
                name: "accessory-1-mid.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory2Value].altSrc :
                Mechs.accessory[unit.accessory2Value].src,
                filter: unit.accessory2TintValue,
                name: "accessory-2-mid.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory3Value].altSrc :
                Mechs.accessory[unit.accessory3Value].src,
                filter: unit.accessory3TintValue,
                name: "accessory-3-mid.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].src) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                Mechs.accessory[unit.accessory4Value].altSrc :
                Mechs.accessory[unit.accessory4Value].src,
                filter: unit.accessory4TintValue,
                name: "accessory-4-mid.png",
            });
        }

        if (unit.headValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.head[unit.headValue].src,
                filter: unit.headTintValue,
                name: "head.png",
            });
        }

        if (unit.armRightValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.arms[unit.armRightValue].src[unit.stanceValue].right,
                filter: unit.armRightTintValue,
                name: "right-arm.png",
            });
        }

        if (unit.armLeftValue !== "NONE" && !(Mechs.arms[unit.armLeftValue].isMounted || Mechs.arms[unit.armLeftValue].isLeftArmBackRow)) {
            imageLayersArray.push({
                src: Mechs.arms[unit.armLeftValue].src[unit.stanceValue].left,
                filter: unit.armLeftTintValue,
                name: "left-arm.png",
            });
        }

        if (unit.secondaryWeaponValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.secondaryWeapon[unit.secondaryWeaponValue].src,
                filter: unit.secondaryWeaponTintValue,
                name: "secondary-weapon.png",
            });
        }

        if (unit.weaponValue !== "NONE") {
            imageLayersArray.push({
                src: Mechs.weapon[unit.stanceValue][unit.weaponValue].src,
                filter: unit.weaponTintValue,
                name: "weapon.png",
            });
        }

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].frontSrc,
                filter: unit.accessory1TintValue,
                name: "accessory-1-front.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].frontSrc,
                filter: unit.accessory2TintValue,
                name: "accessory-2-front.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].frontSrc,
                filter: unit.accessory3TintValue,
                name: "accessory-3-front.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].frontSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].frontSrc,
                filter: unit.accessory4TintValue,
                name: "accessory-4-front.png",
            });
        }

        if (unit.accessory1Value !== "NONE" && !!Mechs.accessory[unit.accessory1Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory1Value].foremostSrc,
                filter: unit.accessory1TintValue,
                name: "accessory-1-foremost.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!Mechs.accessory[unit.accessory2Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory2Value].foremostSrc,
                filter: unit.accessory2TintValue,
                name: "accessory-2-foremost.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!Mechs.accessory[unit.accessory3Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory3Value].foremostSrc,
                filter: unit.accessory3TintValue,
                name: "accessory-3-foremost.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!Mechs.accessory[unit.accessory4Value].foremostSrc) {
            imageLayersArray.push({
                src: Mechs.accessory[unit.accessory4Value].foremostSrc,
                filter: unit.accessory4TintValue,
                name: "accessory-4-foremost.png",
            });
        }

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export Parts",
            label: `${safeName(name)}`,
        });
        CustomMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    async function printCustomPlusMech(unit) {
        let imageLayersArray = [];

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].backSrc,
                filter: unit.accessory1TintValue,
                name: "accessory-1-back.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].backSrc,
                filter: unit.accessory2TintValue,
                name: "accessory-2-back.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].backSrc,
                filter: unit.accessory3TintValue,
                name: "accessory-3-back.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].backSrc,
                filter: unit.accessory4TintValue,
                name: "accessory-4-back.png",
            });
        }

        if (unit.weaponValue !== "NONE" && !!MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].backSrc,
                filter: unit.weaponTintValue,
                name: "weapon-back.png",
            });
        }

        if (unit.secondaryWeaponValue !== "NONE" && !!MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].backSrc,
                filter: unit.secondaryWeaponTintValue,
                name: "secondary-weapon-back.png",
            });
        }

        if (unit.rearMountValue !== "NONE" && !!MechsCustomPlus.rearMount[unit.rearMountValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.rearMount[unit.rearMountValue].backSrc,
                filter: unit.rearMountTintValue,
                name: "rear-mount-back.png",
            });
        }

        if (unit.rearMountValue !== "NONE" && !!MechsCustomPlus.rearMount[unit.rearMountValue].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.rearMount[unit.rearMountValue].src,
                filter: unit.rearMountTintValue,
                name: "rear-mount-mid.png",
            });
        }

        if (unit.legsValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.legs[unit.legsValue].src,
                filter: unit.legsTintValue,
                name: "legs.png",
            });
        }

        if (unit.armLeftValue !== "NONE" && !!MechsCustomPlus.leftArm[unit.armLeftValue].backSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.leftArm[unit.armLeftValue].backSrc,
                filter: unit.armLeftTintValue,
                name: "left-arm-back.png",
            });
        }

        if (unit.chassisValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.chassis[unit.chassisValue].src,
                filter: unit.chassisTintValue,
                name: "chassis.png",
            });
        }

        if (unit.rearMountValue !== "NONE" && !!MechsCustomPlus.rearMount[unit.rearMountValue].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.rearMount[unit.rearMountValue].frontSrc,
                filter: unit.rearMountTintValue,
                name: "rear-mount-front.png",
            });
        }

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory1Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory1Value].src,
                filter: unit.accessory1TintValue,
                name: "accessory-1-mid.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory2Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory2Value].src,
                filter: unit.accessory2TintValue,
                name: "accessory-2-mid.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory3Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory3Value].src,
                filter: unit.accessory3TintValue,
                name: "accessory-3-mid.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].src) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].altSrc && (unit.chassisValue === "3" || unit.chassisValue === "5") ?
                MechsCustomPlus.accessory[unit.accessory4Value].altSrc :
                MechsCustomPlus.accessory[unit.accessory4Value].src,
                filter: unit.accessory4TintValue,
                name: "accessory-4-mid.png",
            });
        }

        if (unit.headValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.head[unit.headValue].src,
                filter: unit.headTintValue,
                name: "head.png",
            });
        }

        if (unit.armRightValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.rightArm[unit.armRightValue].src,
                filter: unit.armRightTintValue,
                name: "right-arm.png",
            });
        }

        if (unit.armLeftValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.leftArm[unit.armLeftValue].src,
                filter: unit.armLeftTintValue,
                name: "left-arm.png",
            });
        }

        if (unit.secondaryWeaponValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.secondaryWeapon[unit.secondaryWeaponValue].src,
                filter: unit.secondaryWeaponTintValue,
                name: "secondary-weapon.png",
            });
        }

        if (unit.weaponValue !== "NONE") {
            imageLayersArray.push({
                src: MechsCustomPlus.weapon[unit.stanceValue][unit.weaponValue].src,
                filter: unit.weaponTintValue,
                name: "weapon.png",
            });
        }

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].frontSrc,
                filter: unit.accessory1TintValue,
                name: "accessory-1-front.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].frontSrc,
                filter: unit.accessory2TintValue,
                name: "accessory-2-front.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].frontSrc,
                filter: unit.accessory3TintValue,
                name: "accessory-3-front.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].frontSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].frontSrc,
                filter: unit.accessory4TintValue,
                name: "accessory-4-front.png",
            });
        }

        if (unit.accessory1Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory1Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory1Value].foremostSrc,
                filter: unit.accessory1TintValue,
                name: "accessory-1-foremost.png",
            });
        }

        if (unit.accessory2Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory2Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory2Value].foremostSrc,
                filter: unit.accessory2TintValue,
                name: "accessory-2-foremost.png",
            });
        }

        if (unit.accessory3Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory3Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory3Value].foremostSrc,
                filter: unit.accessory3TintValue,
                name: "accessory-3-foremost.png",
            });
        }

        if (unit.accessory4Value !== "NONE" && !!MechsCustomPlus.accessory[unit.accessory4Value].foremostSrc) {
            imageLayersArray.push({
                src: MechsCustomPlus.accessory[unit.accessory4Value].foremostSrc,
                filter: unit.accessory4TintValue,
                name: "accessory-4-foremost.png",
            });
        }

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export Parts",
            label: `${safeName(name)}`,
        });
        CustomPlusMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    async function printBrandedMech(unit) {
        let imageLayersArray = createExportLayerOptions({unit, brandList: mechBrandedBrandList, layerOrder: brandedLayerOrder});

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });


        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export Parts",
            label: `${safeName(name)}`,
        });
        BrandedMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    async function printNPCMech(unit) {
        let imageLayersArray = createExportLayerOptions({unit, brandList: mechNPCBrandList, layerOrder: npcLayerOrder});

        let zip = new JSZip();

        const zipFolder = zip.folder(`${safeName(name)}`);

        const canvas = canvasRef.current;
        let ctx = canvas.getContext('2d');

        await drawZipImages({
            ctxObject: ctx,
            canvas,
            zipObject: zipFolder,
            images: imageLayersArray,
            imageSize,
        });

        zip.generateAsync({ type: "blob" }).then(function (blob) {
            saveAs(blob, `Retrograde-Minis-${safeName(name)}.zip`);
        }, function (err) {
            console.log(err);
        });

        ReactGA.event({
            category: "Print",
            action: "Export Parts",
            label: `${safeName(name)}`,
        });
        NPCMechPrintAnalytics(unit, ReactGA);
        setIsLoading(false);
    }

    const ButtonComponent = isButtonThird ? InterfaceThirdButton : InterfaceButton;

    return (
        <ExportWrapper>
            <ButtonComponent
                className={displayTheme}
                disabled={disabled}
                title={"Export All Currently Selected Mech Parts as individual PNGs with currently applied color tints"}
                onClick={disabled || isLoading ? () => {} : () => {
                    if (!isLegendaryUser) {
                        return setShowPremiumPopupFunc(premiumTiersList.LEGENDARY);
                    } else {
                        setIsLoading(true);
                        if (mechType === "CUSTOM") {
                            return printCustomMech(unit);
                        } else if (mechType === "CUSTOM-PLUS") {
                            return printCustomPlusMech(unit);
                        } else if (mechType === "NPC") {
                            return printNPCMech(unit);
                        } else {
                            return printBrandedMech(unit);
                        }
                    }
                }}
            >
                {isLoading ? "LOADING" : "⇣ ZIP ●●"}
            </ButtonComponent>
            <canvas ref={canvasRef} height={imageSize} width={imageSize} />
        </ExportWrapper>
    );
}

export default ExportMechPartsButton;
