import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Misc/MBT`;

// CHASSIS
const chassisTreadsImage = `${imagePath}/Chassis - Tread.png`;
const chassisHoverImage = `${imagePath}/Chassis - Hover.png`;

// TURRETS
// CENTER
const centerATCannonImage = `${imagePath}/weapons/center/Center Mount - AT Cannon.png`;
const centerSmoothboreCannonImage = `${imagePath}/weapons/center/Center Mount - Smoothbore Cannon.png`;

// LEFT
const leftANTARESLaserCannonImage = `${imagePath}/weapons/left/Left Mount - ANTARES Laser Cannon.png`;
const leftATCannonImage = `${imagePath}/weapons/left/Left Mount - AT Cannon.png`;
const leftMissilesImage = `${imagePath}/weapons/left/Left Mount - Missiles.png`;
const leftRailgunImage = `${imagePath}/weapons/left/Left Mount - Railgun.png`;
const leftSmoothboreCannonImage = `${imagePath}/weapons/left/Left Mount - Smoothbore Cannon.png`;
const leftStreetsweeperCanisterLauncherImage = `${imagePath}/weapons/left/Left Mount - Streetsweeper Canister Launcher.png`;

// RIGHT
const rightANTARESLaserCannonImage = `${imagePath}/weapons/right/Right Mount - ANTARES Laser Cannon.png`;
const rightATCannonImage = `${imagePath}/weapons/right/Right Mount - AT Cannon.png`;
const rightMissilesImage = `${imagePath}/weapons/right/Right Mount - Missiles.png`;
const rightSmoothboreCannonImage = `${imagePath}/weapons/right/Right Mount - Smoothbore Cannon.png`;
const rightStreetsweeperCanisterLauncherImage = `${imagePath}/weapons/right/Right Mount - Streetsweeper Canister Launcher.png`;

// ACCESSORIES
const accDecalsHoverImage = `${imagePath}/accessories/Accessories - Decals Hover.png`;
const accDecalsTreadImage = `${imagePath}/accessories/Accessories - Decals Tread.png`;
const accERATreadImage = `${imagePath}/accessories/Accessories - ERA Treads.png`;
const accHeavyArmorImage = `${imagePath}/accessories/Accessories - Heavy Armor.png`;
const accScratchesHoverImage = `${imagePath}/accessories/Accessories - Scratches Hover.png`;
const accScratchesTreadImage = `${imagePath}/accessories/Accessories - Scratches Tread.png`;
const accSiegeStabilizersImage = `${imagePath}/accessories/Accessories - Siege Stabilizers.png`;
// REAR
const rearLHatchImage = `${imagePath}/rear/Secondary Left Mount - Hatch.png`;
const rearRHatchImage = `${imagePath}/rear/Secondary Right Mount - Hatch.png`;
const rearLHMGImage = `${imagePath}/rear/Secondary Left Mount - HMG.png`;
const rearRHMGImage = `${imagePath}/rear/Secondary Right Mount - HMG.png`;
const rearBothHMGImage = `${imagePath}/rear/Secondary HMG BOTH.png`;
const rearLMarkerNexusImage = `${imagePath}/rear/Secondary Left Mount - Marker Nexus.png`;
const rearRMarkerNexusImage = `${imagePath}/rear/Secondary Right Mount - Marker Nexus.png`;
const rearBothMarkerNexusImage = `${imagePath}/rear/Secondary Marker Nexus BOTH.png`;
const rearRMissilePodImage = `${imagePath}/rear/Secondary Right Mount - Missile Pod.png`;
const rearMissilePodUnderImage = `${imagePath}/rear/Under - Missile Pod.png`;
const rearLPDCImage = `${imagePath}/rear/Secondary Left Mount - PDC.png`;
const rearRPDCImage = `${imagePath}/rear/Secondary Right Mount - PDC.png`;
const rearBothPDCImage = `${imagePath}/rear/Secondary PDC BOTH.png`;
const rearLSkylightAALaserImage = `${imagePath}/rear/Secondary Left Mount - Skylight AA Laser.png`;
const rearRSkylightAALaserImage = `${imagePath}/rear/Secondary Right Mount - Skylight AA Laser.png`;
const rearBothSkylightAALaserImage = `${imagePath}/rear/Secondary Skylight BOTH.png`;

// GLOWS
const glowHoverModeImage = `${imagePath}/glows/Glow - Hover Mode.png`;
const glowLANTARESImage = `${imagePath}/glows/Glow - L ANTARES.png`;
const glowRANTARESImage = `${imagePath}/glows/Glow - R ANTARES.png`;
const glowBothANTARESImage = `${imagePath}/glows/GLOW - ANTARES BOTH.png`;
const glowRMarkerNexusImage = `${imagePath}/glows/Glow - R Marker Nexus.png`;
const glowLRailgunImage = `${imagePath}/glows/Glow - Railgun.png`;
const glowLSkylightImage = `${imagePath}/glows/Glow - L Skylight.png`;
const glowRSkylightImage = `${imagePath}/glows/Glow - R Skylight.png`;
const glowBothSkylightImage = `${imagePath}/glows/GLOW - SKYLIGHT BOTH.png`;

const previewImage = `${imagePath}/preview.png`;

const treadsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "100px",
    right: "-6px",
};

const hoverShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "32px",
    right: "-39px",
};

export default {
    previewImg: previewImage,
    rearMountsAreChassis: true,
    isMegadeusPlus: true,
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "4",
        "rearMountValue": "NONE",
        "weaponValue": "2",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "20",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "0",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "ANTARES LASER CANNON",
            src: leftANTARESLaserCannonImage,
        },
        {
            value: 1,
            label: "AT CANNON",
            src: leftATCannonImage,
        },
        {
            value: 2,
            label: "MISSILES",
            src: leftMissilesImage,
        },
        {
            value: 3,
            label: "RAILGUN",
            src: leftRailgunImage,
        },
        {
            value: 4,
            label: "SMOOTHBORE CANNON",
            src: leftSmoothboreCannonImage,
        },
        {
            value: 5,
            label: "STREETSWEEPER CANISTER LAUNCHER",
            src: leftStreetsweeperCanisterLauncherImage,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "TREADS",
            src: chassisTreadsImage,
            shadowDetails: treadsShadow,
        },
        {
            value: 1,
            label: "HOVER",
            src: chassisHoverImage,
            shadowDetails: hoverShadow,
        },
    ],
    weapon: {
        "0": [
            {
                value: 0,
                label: "ANTARES LASER CANNON",
                src: rightANTARESLaserCannonImage,
            },
            {
                value: 1,
                label: "AT CANNON",
                src: rightATCannonImage,
            },
            {
                value: 2,
                label: "MISSILES",
                src: rightMissilesImage,
            },
            {
                value: 3,
                label: "SMOOTHBORE CANNON",
                src: rightSmoothboreCannonImage,
            },
            {
                value: 4,
                label: "STREETSWEEPER CANISTER LAUNCHER",
                src: rightStreetsweeperCanisterLauncherImage,
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "AT CANNON",
            src: centerATCannonImage,
        },
        {
            value: 1,
            label: "SMOOTHBORE CANNON",
            src: centerSmoothboreCannonImage,
        },
    ],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "ACCESSORY - DECALS - HOVER",
            src: accDecalsHoverImage,
        },
        {
            value: 1,
            label: "ACCESSORY - DECALS - TREAD",
            src: accDecalsTreadImage,
        },
        {
            value: 2,
            label: "ACCESSORY - ERA - TREAD",
            src: accERATreadImage,
        },
        {
            value: 3,
            label: "ACCESSORY - HEAVY ARMOR",
            src: accHeavyArmorImage,
        },
        {
            value: 4,
            label: "ACCESSORY - SCRATCHES HOVER",
            src: accScratchesHoverImage,
        },
        {
            value: 5,
            label: "ACCESSORY - SCRATCHES TREAD",
            src: accScratchesTreadImage,
        },
        {
            value: 6,
            label: "ACCESSORY - SIEGE STABILIZERS",
            src: accSiegeStabilizersImage,
        },
        {
            value: 7,
            label: "REAR - HATCH - L",
            frontSrc: rearLHatchImage,
        },
        {
            value: 8,
            label: "REAR - HATCH - R",
            frontSrc: rearRHatchImage,
        },
        {
            value: 9,
            label: "REAR - HMG - L",
            frontSrc: rearLHMGImage,
        },
        {
            value: 10,
            label: "REAR - HMG - R",
            frontSrc: rearRHMGImage,
        },
        {
            value: 11,
            label: "REAR - HMG - BOTH",
            frontSrc: rearBothHMGImage,
        },
        {
            value: 12,
            label: "REAR - MARKER NEXUS - L",
            frontSrc: rearLMarkerNexusImage,
        },
        {
            value: 13,
            label: "REAR - MARKER NEXUS - R",
            frontSrc: rearRMarkerNexusImage,
        },
        {
            value: 14,
            label: "REAR - MARKER NEXUS - BOTH",
            frontSrc: rearBothMarkerNexusImage,
        },
        {
            value: 15,
            label: "REAR - PDC - L",
            frontSrc: rearLPDCImage,
        },
        {
            value: 16,
            label: "REAR - PDC - R",
            frontSrc: rearRPDCImage,
        },
        {
            value: 17,
            label: "REAR - PDC - BOTH",
            frontSrc: rearBothPDCImage,
        },
        {
            value: 18,
            label: "REAR - MISSILE PODS - L",
            backSrc: rearMissilePodUnderImage,
        },
        {
            value: 19,
            label: "REAR - MISSILE PODS - R",
            frontSrc: rearRMissilePodImage,
        },
        {
            value: 20,
            label: "REAR - MISSILE PODS - BOTH",
            frontSrc: rearRMissilePodImage,
            backSrc: rearMissilePodUnderImage,
        },
        {
            value: 21,
            label: "REAR - SKYLIGHT AA LASER - L",
            frontSrc: rearLSkylightAALaserImage,
        },
        {
            value: 22,
            label: "REAR - SKYLIGHT AA LASER - R",
            frontSrc: rearRSkylightAALaserImage,
        },
        {
            value: 23,
            label: "REAR - SKYLIGHT AA LASER - BOTH",
            frontSrc: rearBothSkylightAALaserImage,
        },
        {
            value: 24,
            label: "GLOW - HOVER MODE",
            src: glowHoverModeImage,
        },
        {
            value: 25,
            label: "GLOW - ANTARES - L",
            frontSrc: glowLANTARESImage,
        },
        {
            value: 26,
            label: "GLOW - ANTARES - R",
            frontSrc: glowRANTARESImage,
        },
        {
            value: 27,
            label: "GLOW - ANTARES - BOTH",
            frontSrc: glowBothANTARESImage,
        },
        {
            value: 28,
            label: "GLOW - MARKER NEXUS - R",
            foremostSrc: glowRMarkerNexusImage,
        },
        {
            value: 29,
            label: "GLOW - RAILGUN - L",
            frontSrc: glowLRailgunImage,
        },
        {
            value: 30,
            label: "GLOW - SKYLIGHT - L",
            foremostSrc: glowLSkylightImage,
        },
        {
            value: 31,
            label: "GLOW - SKYLIGHT - R",
            foremostSrc: glowRSkylightImage,
        },
        {
            value: 32,
            label: "GLOW - SKYLIGHT - BOTH",
            foremostSrc: glowBothSkylightImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "ACCESSORIES/GLOWS",
            value: "ACCESSORIES",
        },
        {
            label: "LEFT",
            value: "head",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "RIGHT",
            value: "weapon",
        },
        {
            label: "CENTER",
            value: "secondaryWeapon",
        },
        {
            label: "ACCESSORY 1",
            value: "accessory1",
        },
        {
            label: "ACCESSORY 2",
            value: "accessory2",
        },
        {
            label: "GLOW 1",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "LEFT",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "RIGHT",
        "secondaryWeapon": "CENTER",
        "accessory1": "ACCESSORY 1",
        "accessory2": "ACCESSORY 2",
        "accessory3": "GLOW 1",
        "accessory4": "GLOW 2",
    },
};
