import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";

const imagePath = `${BaseURL}/Mechs/Custom/TogoRTG`;

// BODY
const bodyChassisImage = `${imagePath}/TogoBase.png`;
const armFrontImage = `${imagePath}/TogoArmFront.png`;

// GLOWS
const glowEyeImage = `${imagePath}/TogoEye.png`;
const glowFlamesLImage = `${imagePath}/TogoFlamesR.png`;
const glowFlamesRImage = `${imagePath}/TogoFlamesL.png`;

const previewImage = `${imagePath}/preview.png`;

const core1Image =  `${imagePath}/Togo_full-vivi.png`;

const bipedLegsShadow = {
    size: shadowTypes.SUPREMELY_LARGE,
    bottom: "5px",
    right: "-4px",
};

const originalArtCredit = {
    prefixText: "Created by ",
    title: "Gummies139",
    url: "https://discord.gg/JUF47G5",
};

export default {
    isMegadeusOmega: true,
    previewImg: previewImage,
    coreImg: [
        {
            src: core1Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Vivivision",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "NONE",
        "rearMountValue": "NONE",
        "weaponValue": "NONE",
        "secondaryWeaponValue": "NONE",
        "accessory1Value": "0",
        "accessory2Value": "1",
        "accessory3Value": "2",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [],
    chassis: [
        {
            value: 0,
            label: "ISP-N TOGO",
            src: bodyChassisImage,
            midSrc: armFrontImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit,
        },
    ],
    weapon: {
        "1H": [],
    },
    secondaryWeapon: [],
    rearMount: [],
    accessory: [
        {
            value: 0,
            label: "EYE",
            src: glowEyeImage,
        },
        {
            value: 1,
            label: "FLAMES LEFT",
            src: glowFlamesLImage,
        },
        {
            value: 2,
            label: "FLAMES RIGHT",
            src: glowFlamesRImage,
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "GLOWS",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "GLOW 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "GLOW 2",
            value: "accessory2",
            isGlow: true,
        },
        {
            label: "GLOW 3",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "GLOW 4",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "EMPTY",
        "chassis": "CHASSIS",
        "rearMount": "EMPTY",
        "weapon": "EMPTY",
        "secondaryWeapon": "EMPTY",
        "accessory1": "GLOW 1",
        "accessory2": "GLOW 2",
        "accessory3": "GLOW 3",
        "accessory4": "GLOW 4",
    },
};
